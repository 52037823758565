import React, { useEffect, useState } from "react";
import "../Assets/Css/Profile.css";
import BlueButton from "./BlueButton";
import GrayButton from "./GrayButton";
import { MANAGER_PROFILE, UPDATE_MANAGER } from "./AllApis";

const Profile = () => {
  const [profileData, setProfileData] = useState({
    name: "",
    mobile_number: "",
    email_Id: "",
    mgr_id: null,
    mgr_user_id: null,
    mgr_emp_id: null,
  });
  const [initialProfileData, setInitialProfileData] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const token = localStorage.getItem("authToken");

  const fetchData = async () => {
    try {
      const response = await fetch(MANAGER_PROFILE, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("json data: ", data.managerProfile);
      const fetchedProfileData = {
        id: data?.managerProfile?.mgr_id,
        name: data?.managerProfile?.mgr_name,
        mobile_number: data?.managerProfile?.mgr_phone,
        email_Id: data?.managerProfile?.mgr_email,
        mgr_id: data?.managerProfile?.mgr_id,
        mgr_user_id: data?.managerProfile?.mgr_user_id,
        mgr_emp_id: data?.managerProfile?.mgr_emp_id,
      };
      setProfileData(fetchedProfileData);
      setInitialProfileData(fetchedProfileData);
    } catch (err) {
      console.error("Error occurred", err);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  const handleSave = async () => {
    const payload = {
      mgr_name: profileData.name,
      mgr_email: profileData.email_Id,
      mgr_phone: profileData.mobile_number,
    };
    console.log("payload", payload);
    try {
      const response = await fetch(UPDATE_MANAGER, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      console.log(response);

      if (response.ok) {
        setIsDisabled(false);
        fetchData();
        // setInitialProfileData(profileData);
      } else {
        console.error("Failed to save profile data");
      }
    } catch (err) {
      console.error("Error saving profile data:", err);
    }
  };

  const handleCancel = () => {
    setProfileData(initialProfileData);
    setIsDisabled(false);
  };

  const disableInputs = () => {
    setIsDisabled(true);
  };

  return (
    <section className="profile-section common-section">
      <div className="container w-50">
        <div className="w-50 m-auto ">
          <p className="profile-title">Profile Details</p>
          <p className="createInst-text">Name</p>
          <input
            type="text"
            className="form-control createInst-input"
            placeholder="Name"
            name="name"
            value={profileData.name}
            disabled={!isDisabled}
            onChange={handleChange}
          />

          <p className="createInst-text">Mobile number</p>
          <input
            type="text"
            className="form-control createInst-input"
            placeholder="Mobile"
            name="mobile_number"
            value={profileData.mobile_number}
            maxLength={10}
            disabled={!isDisabled}
            onChange={handleChange}
          />

          <p className="createInst-text">Email ID</p>
          <input
            type="text"
            className="form-control createInst-input"
            placeholder="Email ID"
            name="email_Id"
            value={profileData.email_Id}
            disabled={!isDisabled}
            onChange={handleChange}
          />
          <div className="profile-btn-wrapper gap-4">
            {isDisabled ? (
              <>
                <GrayButton btnName="Cancel" clickHandler={handleCancel} />
                <BlueButton btnName="Save" clickHandler={handleSave} />
              </>
            ) : (
              <GrayButton btnName="Edit" clickHandler={disableInputs} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
