import React, { useEffect, useState, useRef } from "react";
import "../Assets/Css/AddProduct.css";
import { API_START_POINT, PRODUCT_LIST } from "./AllApis";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BlueButton from "./BlueButton";
import Toaster from "./Toaster";
import Loader from "./Loader";

const AddProduct = () => {
  const [inputVal, setInputVal] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [filterProducts, setFilteredProducts] = useState([]);
  const [options, setOptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [productList, setProductList] = useState([]);
  const dropdownRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showToaster, setShowToaster] = useState({
    message: "",
    show: false,
  });
  const navigate = useNavigate();
  const [completionSuccess, setCompletionSuccess] = useState(false);
  const location = useLocation();
  const orderID = location?.state?.ord_id;
  console.log("orderID", orderID);
  const token = localStorage.getItem("authToken");

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await fetch(PRODUCT_LIST, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setLoading(false);
      return data.allProducts || [];
    } catch (error) {
      console.error("Error fetching products:", error);
      setLoading(false);
      return [];
    }
  };

  const fetchOrderProducts = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_START_POINT}/getProductsByOrder/${orderID}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setLoading(false);
      return data.data || [];
    } catch (error) {
      console.error("Error fetching order products:", error);
      setLoading(false);
      return [];
    }
  };

  const initializeData = async () => {
    const [allProducts, orderProducts] = await Promise.all([
      fetchProducts(),
      fetchOrderProducts(),
    ]);

    setProductList(orderProducts);

    const orderProductSKUs = orderProducts.map(
      (product) => product.prod_sku_no
    );
    const filteredOptions = allProducts.filter(
      (product) => !orderProductSKUs.includes(product.prod_sku_no)
    );

    setOptions(filteredOptions);
    setFilteredProducts(filteredOptions.slice(0, 5));
  };

  useEffect(() => {
    initializeData();
  }, [orderID, token]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClick = () => {
    window.open("/create-product", "_blank");
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputVal(value);
    setShowDropdown(true);
    if (value) {
      const filtered = options.filter(
        (option) =>
          option.prod_name.toLowerCase().includes(value.toLowerCase()) ||
          option.prod_sku_no.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(options.slice(0, 5));
      setShowDropdown(false);
    }
  };

  const handleSelectChange = (sku) => {
    const selectedProduct = options.find(
      (option) => option.prod_sku_no === sku
    );
    if (
      selectedProduct &&
      !selectedProducts.some((product) => product.prod_sku_no === sku)
    ) {
      selectedProduct.quantity = 1;
      setSelectedProducts([...selectedProducts, selectedProduct]);
      setOptions(options.filter((option) => option.prod_sku_no !== sku));
      setInputVal("");
      setFilteredProducts([]);
      setShowDropdown(false);
    }
  };

  const handleQuantityChange = (event, sku) => {
    const quantity = event.target.value.replace(/\D/g, "");
    setSelectedProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.prod_sku_no === sku
          ? { ...product, quantity: Number(quantity) }
          : product
      )
    );
    setProductList((prevProducts) =>
      prevProducts.map((product) =>
        product.prod_sku_no === sku
          ? { ...product, op_prod_qty: Number(quantity) }
          : product
      )
    );
  };

  const handleSaveList = async () => {
    const combinedProducts = [...productList, ...selectedProducts];

    try {
      const uniqueCombinedProducts = combinedProducts.filter(
        (product, index, self) =>
          index === self.findIndex((p) => p.prod_sku_no === product.prod_sku_no)
      );
      const payload = {
        products: combinedProducts.map((product) => ({
          prod_sku_no: product.prod_sku_no,
          op_prod_qty: product.quantity || product.op_prod_qty,
        })),
      };

      const response = await fetch(
        `${API_START_POINT}/orders/${orderID}/add-products`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );
      console.log("response", response);
      if (response.ok) {
        setShowToaster({
          message: "List saved successfully",
          show: true,
        });
        setShowModal(false);
        setCompletionSuccess(true);
      } else {
        const errorData = await response.json();
        setShowToaster({
          message: "Something went wrong.Please try again later",
          show: true,
        });
        setShowModal(false);
      }
    } catch (error) {
      console.error("Error saving list:", error);
      setShowToaster({
        message: "Something went wrong.Please try again later",
        show: true,
      });
      setShowModal(false);
    }
  };
  const onclosefunction = () => {
    setShowToaster({ ...showToaster, show: false });
    if (completionSuccess) {
      navigate("/all-orders");
    }
  };

  const deleteProduct = async (op_id, sku) => {
    try {
      const response = await fetch(
        `${API_START_POINT}/deleteProductFromOrder/${op_id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();

      initializeData();

      setProductList((prevProducts) =>
        prevProducts.filter((product) => product.op_id !== op_id)
      );

      const deletedProduct = selectedProducts.find(
        (product) => product.prod_sku_no === sku
      );
      if (deletedProduct) {
        setOptions((prevOptions) => [...prevOptions, deletedProduct]);
        setSelectedProducts((prevProducts) =>
          prevProducts.filter((product) => product.prod_sku_no !== sku)
        );
      }

      setFilteredProducts(options.slice(0, 5));

      setShowDropdown(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  return (
    <section className="addProduct common-section">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="container-fluid">
            <div className="container">
              <p className="addProduct-title">Add Products</p>
              <div>
                {showToaster.show && (
                  <Toaster
                    showToaster={showToaster}
                    onClose={onclosefunction}
                  />
                )}
                <p className="addProduct-text">Search for a product</p>
                <div className="row mb-5">
                  <div className="col-6">
                    <input
                      type="text"
                      className="form-control addProduct-input addProduct-search"
                      onChange={handleInputChange}
                      value={inputVal}
                      placeholder="SKU, product name or barcode"
                      onClick={() => setFilteredProducts(options.slice(0, 5))}
                    />
                    {showDropdown && filterProducts.length > 0 && (
                      <div className="dropdown-container" ref={dropdownRef}>
                        <ul className="list-group addProduct-input addProduct-dropdown">
                          {filterProducts.map((option, index) => (
                            <li
                              key={index}
                              className="list-group-item addProduct-dropdown-options"
                              onClick={() =>
                                handleSelectChange(option.prod_sku_no)
                              }
                            >
                              {index + 1} - ({option.prod_sku_no}) :{" "}
                              {option.prod_name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  <div className="addProduct-btn-wrapper col-6">
                    <button
                      type="submit"
                      className="btn btn-primary addProduct-btn"
                      onClick={handleClick}
                    >
                      Add New Product
                    </button>
                  </div>
                </div>
                <p className="addProduct-text">Products to add</p>
                <div
                  className="addProduct-table-wrapper table-responsive"
                  style={{ maxHeight: "500px", overflowY: "auto" }}
                >
                  <table className="table table-borderless addProduct-table align-top">
                    <thead>
                      <tr className="align-top">
                        <th>Sr no.</th>
                        <th>SKU no.</th>
                        <th>Product name</th>
                        <th>Quantity</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="align-middle">
                      {[...productList, ...selectedProducts].map(
                        (data, ind) => (
                          <tr key={ind}>
                            <td>{ind + 1}</td>
                            <td>{data.prod_sku_no}</td>
                            <td>{data.prod_name}</td>
                            <td>
                              <input
                                type="text"
                                className="addProduct-QntyInput"
                                value={data.quantity || data.op_prod_qty}
                                onChange={(event) =>
                                  handleQuantityChange(event, data.prod_sku_no)
                                }
                              />
                            </td>
                            <td>
                              <button
                                className="btn btn-danger addProduct-deleteBtn"
                                onClick={() =>
                                  deleteProduct(data.op_id, data.prod_sku_no)
                                }
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="addProduct-saveBtn-wrapper">
                  <button
                    className="btn btn-primary"
                    onClick={() => setShowModal(true)}
                  >
                    Save List
                  </button>
                </div>
              </div>
            </div>

            {showModal && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "36rem",
                }}
              >
                <div>
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="btn-close"
                        onClick={() => setShowModal(false)}
                      ></button>
                    </div>
                    <div
                      className="modal-body"
                      style={{ maxHeight: "500px", overflowY: "auto" }}
                    >
                      <p className="common-sub-title">Confirm product list</p>
                      {[...productList, ...selectedProducts].length > 0 ? (
                        <div className="table-container">
                          <table className="table table-borderless align-top confirm-list-table">
                            <thead>
                              <tr className="align-top">
                                <th>Sr no.</th>
                                <th>SKU no.</th>
                                <th>Product name</th>
                                <th>Quantity</th>
                              </tr>
                            </thead>
                            <tbody className="align-middle confirm-list-table-body">
                              {[...productList, ...selectedProducts].map(
                                (data, ind) => (
                                  <tr key={ind}>
                                    <td>{ind + 1}</td>
                                    <td>{data.prod_sku_no}</td>
                                    <td>{data.prod_name}</td>
                                    <td>{data.quantity || data.op_prod_qty}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <p>No data</p>
                      )}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary py-2"
                        onClick={() => setShowModal(false)}
                        style={{ borderRadius: "12px" }}
                      >
                        Cancel
                      </button>

                      <button
                        className="common-blue-button btn my-auto"
                        onClick={handleSaveList}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <ToastContainer />{" "}
        </>
      )}
    </section>
  );
};
export default AddProduct;
