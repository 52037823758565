import React, { useEffect, useState } from "react";
import SchoolsList from "./SchoolsList";
import "../Assets/Css/Activities.css";
import { GET_ACTIVITIES } from "../Components/AllApis";
import useFetchAPI from "../Custom Hooks/useFetchAPI";
import Loader from "../Components/Loader";

const Activities = () => {
  const [activity, setActivity] = useState([]);

  const { data, error, loading, triggerFetch } = useFetchAPI(
    GET_ACTIVITIES,
    "GET"
  );

  useEffect(() => {
    triggerFetch();
  }, []);

  useEffect(() => {
    if (data) {
      setActivity(data.activity_data);
    }
  }, [data]);

  return (
    <section className="common-section activites-section">
      {loading ? (
        <Loader />
      ) : (
        <div className="container pt-4 activities-list-wrapper">
          <p className="activities-title">Activities</p>
          <ul className="mt-5">
            {activity?.length > 0 &&
              activity?.map((val, ind) => {
                return (
                  <li key={ind} className="school-name mb-2">
                    {val.act_message}
                  </li>
                );
              })}
          </ul>
        </div>
      )}
      {/* <SchoolsList /> */}
    </section>
  );
};

export default Activities;
