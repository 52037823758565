import React from "react";
import { CSVLink } from "react-csv";

const ExportData = ({ data, filename }) => {
  return (
    <div>
      <CSVLink
        data={data}
        className="common-gray-button btn"
        filename={filename}
      >
        Export
      </CSVLink>
    </div>
  );
};

export default ExportData;
