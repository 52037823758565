import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_START_POINT } from "../Components/AllApis";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useFetchAPI from "../Custom Hooks/useFetchAPI";
import Toaster from "../Components/Toaster";
import Loader from "../Components/Loader";

const DeliveryConfirmation = () => {
  const [deliveryData, setDeliveryData] = useState([]);
  const [showToaster, setShowToaster] = useState({
    message: "",
    show: false,
  });
  const location = useLocation();
  const order_id = location.state?.ord_id;
  const [orderID] = useState(order_id);
  const [completionSuccess, setCompletionSuccess] = useState(false);
  const [dateInput, setDateInput] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [todaysDate, setTodaysDate] = useState("");

  const { data, error, loading, triggerFetch } = useFetchAPI(
    `${API_START_POINT}/getRecieverDetails/${orderID}`,
    "GET"
  );
  useEffect(() => {
    triggerFetch();
  }, []);

  useEffect(() => {
    if (data && data.order) {
      setDeliveryData(data.order);
    }
  }, [data]);

  const onclosefunction = () => {
    setShowToaster({ ...showToaster, show: false });
    if (completionSuccess) {
      navigate("/all-orders");
      window.location.reload();
    }
  };

  const validateDate = (date) => {
    const currentDate = new Date();
    const selectedDate = new Date(date);
    const isValid = selectedDate > currentDate;
    return isValid;
  };

  const confirmHandler = () => {
    let dataErrors = {};
    if (!validateDate(dateInput)) {
      dataErrors.date = "Please select date and time";
      setErrors(dataErrors);
      return;
    }
    setErrors({});

    const token = localStorage.getItem("authToken");
    axios({
      method: "post",
      url: `${API_START_POINT}/mgrApproveDeliveryConfirmation/${orderID}`,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ls_date: dateInput,
      },
    })
      .then(function (response) {
        console.log(response);
        if (
          response.data.message ===
          "Delivery confirmation data approved and order status updated to Lab Setup."
        ) {
          setShowToaster({
            message:
              "Delivery confirmation data approved and order status updated to Lab Setup.",
            show: true,
          });
          setCompletionSuccess(true);
        }
      })
      .catch(function (error) {
        console.error(error);
        setShowToaster({
          message: "Something went wrong. Please try again later.",
          show: true,
        });
      });
  };


  useEffect(() => {
    // Function to get the current date and time in the format YYYY-MM-DDTHH:MM
    const getCurrentDateTime = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    setTodaysDate(getCurrentDateTime());
  }, []);

  return (
    <section
      className="common-section delivery-confirmation-section centerd-section"
      style={{ marginLeft: "30%" }}
    >
      {showToaster.show && (
        <Toaster showToaster={showToaster} onClose={onclosefunction} />
      )}
      {loading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="d-flex justify-content-between">
            <p className="common-title mt-5 mb-2">Delivery Confirmation</p>
          </div>
          <p className="sub-title">Order#{orderID}</p>

          <p className="common-sub-title mt-5">Receiver Information</p>

          <div className="d-flex gap-2 mt-4">
            <img
              src={require("../Assets/Images/Common/Profile_pic.png")}
              alt="Profile pic"
              width="20px"
              height="20px"
            />
            <p className="title mb-2">
              {deliveryData?.ord_disp_receiver_name || "-"}
            </p>
          </div>
          <div className="d-flex gap-2 ">
            <img
              src={require("../Assets/Images/Common/call.png")}
              alt="Phone pic"
              width="20px"
              height="20px"
            />
            <p className="sub-title">
              {deliveryData?.ord_disp_receiver_phone || "-"}
            </p>
          </div>
          <div className="d-flex gap-2 ">
            <img
              src={require("../Assets/Images/Common/email.png")}
              alt="email pic"
              width="20px"
              height="20px"
            />
            <p className="sub-title">
              {deliveryData?.ord_disp_receiver_email
                ? deliveryData?.ord_disp_receiver_email
                : "-"}
            </p>
          </div>

          <p className="common-sub-title mt-5">Signature</p>
          {deliveryData.ord_disp_receiver_sign ? (
            <img
              src={deliveryData.ord_disp_receiver_sign}
              alt="signature-img"
              width="200px"
            />
          ) : (
            <img
              src={require("../Assets/Images/Common/signature-img.png")}
              alt="signature-img"
              width="200px"
            />
          )}
          <div className="mt-5">
            <button
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              Submit
            </button>
          </div>

          <div
            className="modal fade w-100"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <p className="common-sub-title">
                    Allocate date & time for lab setup
                  </p>
                  <div className="row">
                    <div className="col">
                      <p className="addSchool-text">Date & Time</p>
                      <input
                        type="datetime-local"
                        className="form-control addSchool-date-time-input"
                        name="date"
                        value={dateInput}
                        onChange={(e) => setDateInput(e.target.value)}
                        min={todaysDate}
                      />
                      {errors.date && (
                        <p className="error-text">{errors.date}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary py-2"
                    style={{ borderRadius: "12px" }}
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary py-2"
                    style={{ borderRadius: "12px" }}
                    onClick={confirmHandler}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default DeliveryConfirmation;
