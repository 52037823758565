import React, { useState, useEffect } from "react";
import "../Assets/Css/Overview.css";
import { Link } from "react-router-dom";
import { DASHBOARD_COUNT } from "../Components/AllApis";

const Overview = () => {
  const [cards, setCards] = useState([
    {
      title: "New",
      count: "-",
      cardColor: "rgba(213, 213, 213, 0.3)",
    },
    {
      title: "OnGoing",
      count: "-",
      cardColor: " rgba(179, 217, 255, 0.3)",
    },
    {
      title: "Warehouse",
      count: "-",
      cardColor: "rgba(255, 236, 189, 0.3)",
    },
    {
      title: "Dispatch",
      count: "-",
      cardColor: "rgba(255, 248, 179, 0.3)",
    },
    {
      title: "OutForDelivery",
      count: "-",
      cardColor: "rgba(255, 189, 189, 0.3)",
    },
    {
      title: "Delivered",
      count: "-",
      cardColor: "rgba(255, 184, 67, 0.3)",
    },
    {
      title: "LabSetup",
      count: "-",
      cardColor: "rgba(141, 117, 92, 0.3)",
    },
    {
      title: "Complete",
      count: "-",
      cardColor: "rgba(8, 255, 18, 0.3)",
    },
  ]);

  const token = localStorage.getItem("authToken");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(DASHBOARD_COUNT, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        const updatedCards = cards.map((card) => {
          const count = data[card.title.replace(" ", "")];
          return {
            ...card,
            count: count !== undefined ? count : card.count,
          };
        });

        setCards(updatedCards);
      } catch (err) {
        console.error("Error Occured", err);
      }
    };

    fetchData();
  }, []);

  return (
    <section className="overview-section">
      <div className="container">
        <p className="common-title">Overview</p>
        <div className="overview-cards">
          {cards.map((data, ind) => {
            return (
              <div
                className="card"
                key={ind}
                style={{ backgroundColor: data.cardColor }}
              >
                <Link
                  to="/all-orders"
                  onClick={() => localStorage.setItem("activeTab", data.title)}
                  style={{ textDecoration: "none" }}
                >
                  <p className="cards-count">{data.count}</p>
                  <p className="cards-title">{data.title}</p>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Overview;
