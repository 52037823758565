import React from "react";
import { Link } from "react-router-dom";

const BlueButton = (props) => {
  const linkTo = props?.to ?? "";
  const btnName = props.btnName;
  const stateData = props?.state ?? "";
  const clickHandler = props?.clickHandler ?? "";
  const type = props?.type ?? "";
  return (
    <>
      <Link
        className="common-blue-button btn my-auto"
        to={linkTo}
        state={stateData}
        onClick={clickHandler}
        type={type}
      >
        {btnName}
      </Link>
    </>
  );
};

export default BlueButton;
