import { useState } from "react";
import axios from "axios";

const useFetchAPI = (url, method = "GET") => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const triggerFetch = async (bodyData) => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem("authToken");
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      let response;
      if (method === "POST") {
        response = await axios.post(url, bodyData, { headers });
      } else {
        response = await axios.get(url, { headers });
      }
      if (response.status === 200 || response.data.status === "success") {
        setData(response.data);
      } else {
        throw new Error(response.statusText);
      }
      // console.log('response',response);
    } catch (error) {
      setError(error);
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return { data, error, loading, triggerFetch };
};

export default useFetchAPI;
