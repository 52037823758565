import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BlueButton from "../Components/BlueButton";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import {
  CREATE_ORDER_GET_INSTALLER_LIST_API,
  DELETE_INSTALLER,
  UPDATE_INSTALLER_DATA,
} from "../Components/AllApis";
import "../Assets/Css/Installer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faEdit } from "@fortawesome/free-solid-svg-icons";
import GrayButton from "../Components/GrayButton";
import Loader from "../Components/Loader";

const InstallerList = () => {
  const token = localStorage.getItem("authToken");
  const [installerList, setInstallerList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentInstaller, setCurrentInstaller] = useState([]);
  const [installerToDelete, setInstallerToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchInstaller = () => {
    setIsLoading(true);
    axios
      .get(CREATE_ORDER_GET_INSTALLER_LIST_API, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setInstallerList(response.data.allInstallers);
        console.log('data: ',response.data.allInstallers)
        setIsLoading(false);
      })
      .catch(function (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchInstaller();
  }, []);

  const handleEdit = (data) => {
    setShowModal(true);
    setCurrentInstaller(data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentInstaller((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdate = () => {
    const payLoad = {
      inst_id: currentInstaller.inst_id,
      inst_name: currentInstaller.inst_name,
      inst_phone: currentInstaller.inst_phone,
      inst_email: currentInstaller.inst_email,
      inst_address:currentInstaller.inst_address,
    };
    axios
      .post(UPDATE_INSTALLER_DATA(currentInstaller.inst_id), payLoad, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        toast.success("Installers Details Updated Successfully!", {
          position: "top-center",
          autoClose: 1000,
        });
        setTimeout(() => {
          setShowModal(false);
          fetchInstaller();
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.error("Error Occurred", err);
        toast.error("Something goes wrong!", {
          position: "top-center",
          autoClose: 2000,
        });
      });
  };

  const handleDelete = (data) => {
    setInstallerToDelete(data);
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
    axios
      .delete(DELETE_INSTALLER(installerToDelete.inst_id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        toast.success("Deleted successfully!", {
          position: "top-center",
          autoClose: 1000,
        });
        setTimeout(() => {
          setShowDeleteModal(false);
          fetchInstaller();
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.error("Error Occurred", err);
        toast.error("Something goes wrong!", {
          position: "top-center",
          autoClose: 2000,
        });
      });
  };

  const sortedInstallerList = installerList.sort((a, b) => {
    return new Date(b.updated_at) - new Date(a.updated_at);
  });
  const filteredOrders = sortedInstallerList?.filter((order) => {
    const query = searchQuery.toLowerCase();
    return (
      order.inst_phone.toLowerCase().includes(query) ||
      order.inst_name.toLowerCase().includes(query) ||
      order.inst_email.toString().toLowerCase().includes(query)
    );
  });
  return (
    <section className="common-section installerList-section">
      {loading ? (
        <Loader />
      ) : (
        <div className="container">
          <ToastContainer />
          <div className="d-flex justify-content-between">
            <p className="common-title my-auto">Installer's List</p>
            <BlueButton btnName="Add Installer" to="/create-installer" />
          </div>

          <div className="search-bar mt-3">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  <img
                    src={require("../Assets/Images/Common/search-icon.png")}
                    alt="search-icon"
                  />
                </span>
              </div>
              <input
                type="search"
                className="form-control"
                placeholder="Search Orders"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
          <div
            className="order-table mt-4"
            style={{ maxHeight: "800px", overflowY: "auto" }}
          >
            {filteredOrders.length > 0 ? (
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" className="table-heading">
                      Sr.No
                    </th>
                    <th scope="col" className="table-heading">
                      Name
                    </th>
                    <th scope="col" className="table-heading">
                      Mobile No.
                    </th>
                    <th scope="col" className="table-heading">
                      Email ID
                    </th>
                    <th scope="col" className="table-heading">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredOrders?.map((data, ind) => {
                    return (
                      <tr key={data.inst_id}>
                        <td>{ind + 1}</td>
                        <td>{data.inst_name}</td>
                        <td>{data.inst_phone}</td>
                        <td>{data.inst_email}</td>
                        <td className="d-flex justify-content-center">
                          <div className="edit-icon-wrapper">
                            <FontAwesomeIcon
                              icon={faEdit}
                              size="lg"
                              data-bs-toggle="modal"
                              data-bs-target="#InstallerListModal"
                              onClick={() => handleEdit(data)}
                            />
                          </div>
                          &nbsp; /&nbsp; &nbsp;
                          <div className="delete-icon-wrapper">
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              size="xl"
                              onClick={() => handleDelete(data)}
                              style={{ color: "#e62b0a" }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <p className="text-center no-data-msg">No data found</p>
            )}
          </div>
          <div>
            {currentInstaller && (
              <div
                className={`modal fade ${showModal ? "show" : ""}`}
                id="InstallerListModal"
                tabIndex="-1"
                aria-labelledby="InstallerListModalLabel"
                aria-hidden={!showModal}
                style={{ display: showModal ? "block" : "none" }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content card">
                    <div className="card-body">
                      <div className="modal-header">
                        <h1
                          className="modal-title fs-5"
                          id="InstallerListModalLabel"
                        >
                          Edit Installer
                        </h1>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => setShowModal(false)}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <form>
                          <div>
                            
                            <label className="form-label installerList-modal-text">
                              Name{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control installerList-modal-input"
                              value={currentInstaller?.inst_name}
                              name="inst_name"
                              onChange={handleChange}
                            />
                            <label className="installerList-modal-text">
                              Mobile number
                            </label>
                            <input
                              type="text"
                              className="form-control installerList-modal-input"
                              name="inst_phone"
                              value={currentInstaller.inst_phone}
                              maxLength={10}
                              onChange={handleChange}
                            />
                            <p className="installerList-modal-text">Email ID</p>
                            <input
                              type="text"
                              className="form-control installerList-modal-input"
                              name="inst_email"
                              value={currentInstaller.inst_email}
                              onChange={handleChange}
                            />
                            <label className="form-label installerList-modal-text">
                              Employee ID{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control installerList-modal-input"
                              value={currentInstaller?.inst_id}
                              name="employee_id"
                              readOnly
                              // onChange={handleChange}
                            />
                            <label className="form-label installerList-modal-text">
                              Address{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control installerList-modal-input"
                              value={currentInstaller?.inst_address}
                              name="inst_address"
                              // readOnly
                              onChange={handleChange}
                            />
                          </div>
                          <div className="d-flex justify-content-center mt-4">
                            <BlueButton
                              btnName="Update"
                              clickHandler={handleUpdate}
                            ></BlueButton>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showDeleteModal && (
              <div
                className="modal fade show"
                tabIndex="-1"
                style={{
                  display: "block",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
                aria-labelledby="deleteModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content card">
                    <div className="card-body">
                      <div className="modal-header">
                        <h5 className="modal-title" id="deleteModalLabel">
                          Confirm Delete
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          aria-label="Close"
                          onClick={() => setShowDeleteModal(false)}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <p>Are you sure you want to delete this installer?</p>
                        <div className="d-flex justify-content-center mt-4 gap-5">
                          <BlueButton
                            btnName="Yes, Delete"
                            clickHandler={confirmDelete}
                          />
                          <GrayButton
                            btnName="Cancel"
                            clickHandler={() => setShowDeleteModal(false)}
                          ></GrayButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default InstallerList;
