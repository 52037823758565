import React, { useEffect, useState } from "react";
import BlueButton from "../Components/BlueButton";
import GrayButton from "../Components/GrayButton";
import { API_START_POINT } from "../Components/AllApis";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../Components/Loader";
import Toaster from "../Components/Toaster";
import { PhotoProvider, PhotoView } from "react-photo-view";

const SurveySubmission = () => {
  const location = useLocation();
  const orderData = location?.state;
  console.log(orderData);
  const [surveyData, setSurveyData] = useState();
  const [loading, setLoading] = useState(false);
  const [showToaster, setShowToaster] = useState({
    message: "",
    show: false,
  });
  const navigate = useNavigate();
  const [completionSuccess, setCompletionSuccess] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    setLoading(true);
    axios
      .get(`${API_START_POINT}/getSurveyData/${orderData?.ord_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setLoading(false);
        console.log("response", response);
        setSurveyData(response?.data?.survey_data);
      })
      .catch(function (error) {
        setLoading(false);

        console.error("Error fetching data:", error);
      });
  }, [orderData?.ord_id]);

  const approveHandler = () => {
    const token = localStorage.getItem("authToken");
    axios({
      method: "post",
      url: `${API_START_POINT}/mgrApproveSurvey/${orderData?.ord_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        if (
          response.data.message ===
          "Survey data approved and order status updated."
        ) {
          setShowToaster({
            message: "Survey data approved and order status updated.",
            show: true,
          });
          setCompletionSuccess(true);
        }
      })
      .catch(function (error) {
        console.error(error);
        setShowToaster({
          message: "Something went wrong.Please try again later",
          show: true,
        });
        const modal = document.getElementById("exampleModal");
        if (modal) {
          modal.classList.remove("show");
          modal.style.display = "none";
          const modalBackdrop = document.querySelector(".modal-backdrop");
          if (modalBackdrop) modalBackdrop.remove();
        }
      });
  };
  const onclosefunction = () => {
    setShowToaster({ ...showToaster, show: false });
    if (completionSuccess) {
      navigate("/all-orders");
      window.location.reload();
    }
  };
  return (
    <section className="common-section survey-section centerd-section">
      {loading ? (
        <Loader />
      ) : (
        <>
          {showToaster.show && (
            <Toaster showToaster={showToaster} onClose={onclosefunction} />
          )}
          <div className="container mb-5 pb-5 w-50">
            <p className="common-title text-center mt-4">Survey Submission</p>
            <div className="centerd-section mb-4">
              <div className="d-flex justify-content-between">
                <p className="common-sub-title mt-4">Measurements</p>
                <BlueButton
                  btnName="Edit Order"
                  to="/edit-school"
                  state={orderData?.ord_id}
                />
              </div>
              <div className="measurements mt-3">
                <div className="row border-top  border-bottom py-3">
                  <div className="container">
                    {surveyData?.ord_surv_measurements
                      ? surveyData?.ord_surv_measurements
                      : "No Data Available"}
                  </div>
                </div>
              </div>
              <p className="common-sub-title mt-5">Photos</p>
              <div className="lab-setup-images">
                {surveyData?.ord_surv_images ? (
                  <PhotoProvider>
                    <div className="row">
                      {surveyData.ord_surv_images.map((val, ind) => (
                        <div className="col-md-4" key={ind}>
                          <PhotoView src={val}>
                            <img
                              src={val}
                              alt=""
                              height="250px"
                              style={{ cursor: "pointer" }}
                            />
                          </PhotoView>
                        </div>
                      ))}
                    </div>
                  </PhotoProvider>
                ) : (
                  <p>No Photos Available</p>
                )}
              </div>
              <p className="common-sub-title mt-5">Point of Contact</p>
              <div className="d-flex gap-2 mt-4">
                <img
                  src={require("../Assets/Images/Common/Profile_pic.png")}
                  alt="Profile pic"
                  width="20px"
                  height="20px"
                />
                <p className="title mb-2">
                  {orderData?.ord_poc_name ? orderData?.ord_poc_name : "-"}
                </p>
              </div>
              <div className="d-flex gap-2 ">
                <img
                  src={require("../Assets/Images/Common/call.png")}
                  alt="Phone pic"
                  width="20px"
                  height="20px"
                />
                <p className="sub-title">
                  {orderData?.ord_poc_phone ? orderData?.ord_poc_phone : "-"}
                </p>
              </div>
              <div className="d-flex gap-2 ">
                <img
                  src={require("../Assets/Images/Common/email.png")}
                  alt="email pic"
                  width="20px"
                  height="20px"
                />
                <p className="sub-title">
                  {orderData?.ord_poc_email ? orderData?.ord_poc_email : "-"}
                </p>
              </div>
              <div
                className="buttons float-end d-flex gap-4 mt-5"
              >
                {/* <GrayButton btnName="Request changes" /> */}
                {/* <BlueButton btnName="Approve" clickHandler={approveHandler} /> */}

                <Link
                  className="common-blue-button btn"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style={{marginBottom:'24px'}}
                >
                  Approve
                </Link>

                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <p className="common-sub-title">
                          Do you want to approve ?
                        </p>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary py-2"
                          data-bs-dismiss="modal"
                          style={{ borderRadius: "12px" }}
                        >
                          Cancel
                        </button>
                        <BlueButton
                          btnName="Approve"
                          clickHandler={approveHandler}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </>
      )}
    </section>
  );
};

export default SurveySubmission;
