import React, { useEffect, useState } from "react";
import "../Assets/Css/OrderDispatch.css";
import BlueButton from "../Components/BlueButton";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { API_START_POINT } from "../Components/AllApis";
import Loader from "../Components/Loader";

const OrderDispatch = () => {
  const location = useLocation();
  const order_id = location?.state?.ord_id;
  const token = localStorage.getItem("authToken");
  const [orderDetails, setOrderDetails] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_START_POINT}/getProductsForDispatch/${order_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setOrderDetails(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [order_id]);

  const filteredOrders = orderDetails.filter((order) => {
    const query = searchQuery.toLowerCase();
    return (
      order.prod_sku_no.toLowerCase().includes(query) ||
      order.prod_name.toLowerCase().includes(query) ||
      order.op_prod_qty.toString().toLowerCase().includes(query) ||
      order.inst_name.toLowerCase().includes(query)
    );
  });

  return (
    <section className="order-dispatch-section common-section">
      {loading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="d-flex justify-content-between">
            <p className="common-title">Ready to Dispatch</p>

            <BlueButton
              btnName="Edit Order"
              to="/edit-school"
              state={order_id}
            />
          </div>
          <div className="search-bar mt-3">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  <img
                    src={require("../Assets/Images/Common/search-icon.png")}
                    alt="search-icon"
                  />
                </span>
              </div>
              <input
                type="search"
                className="form-control"
                placeholder="Search Orders"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
          <div className="order-table mt-5">
            {filteredOrders.length > 0 ? (
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" className="table-heading">
                      Sr No.
                    </th>
                    <th scope="col" className="table-heading">
                      SKU No.
                    </th>
                    <th scope="col" className="table-heading">
                      Product Name
                    </th>
                    <th scope="col" className="table-heading">
                      Quantity
                    </th>
                    <th scope="col" className="table-heading">
                      Verified By
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredOrders.map((order, ind) => (
                    <tr key={ind}>
                      <td>{ind + 1}</td>
                      <td>{order.prod_sku_no}</td>
                      <td>{order.prod_name}</td>
                      <td>{order.op_prod_qty}</td>
                      <td>{order.inst_name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-center no-data-msg">No data found</p>
            )}
          </div>
          <div className="approved-btn mt-4">
            <BlueButton btnName="Approve" to="/submit-order" state={order_id} />
          </div>
        </div>
      )}
    </section>
  );
};

export default OrderDispatch;
