import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetchAPI from "../Custom Hooks/useFetchAPI";
import { GET_ISSUES_LIST } from "../Components/AllApis";

const IssuesList = () => {
  const navigate = useNavigate();
  const [issueList, setIssueList] = useState([]);
  const { data, error, loading, triggerFetch } = useFetchAPI(
    GET_ISSUES_LIST,
    "GET"
  );
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    triggerFetch();
  }, []);

  useEffect(() => {
    if (data) {
      console.log(data);
      setIssueList(data);
    }
  }, [data]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-IN", options);
  };

  const filteredData = issueList?.filter((data) => {
    const query = searchQuery.toLowerCase();
    const orderIdString = data?.is_ord_id?.toString();
    const formattedDate = formatDate(data?.created_at).toLowerCase();
    
    return (
      orderIdString.includes(query) ||
      data?.is_report_type?.toLowerCase().includes(query) ||
      formattedDate.includes(query) ||
      data?.inst_name?.toLowerCase().includes(query) ||
      data?.is_report_description?.toLowerCase().includes(query)
    );
  });

  // const filteredData = issueList?.filter((data) => {
  //   const query = searchQuery.toLowerCase();
  //   return (
  //     data?.is_ord_id?.toString().includes(query) ||
  //     data?.is_report_type?.toLowerCase().includes(query) ||
  //     data?.created_at?.toString().toLowerCase().includes(query) ||
  //     data?.inst_name?.toLowerCase().includes(query) ||
  //     data?.is_report_description?.toLowerCase().includes(query)
  //   );
  // });

  const linkToIssue = (order_id) => {
    navigate("/issue-reported", { state: order_id });
  };
  return (
    <section className="common-section">
      <div className="container">
        <p className="common-title">Report Issues</p>
        <div className="search-bar mt-3">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                <img
                  src={require("../Assets/Images/Common/search-icon.png")}
                  alt="search-icon"
                />
              </span>
            </div>
            <input
              type="search"
              className="form-control"
              placeholder="Search For Issues"
              aria-label="Search"
              aria-describedby="basic-addon1"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        <div className="order-table mt-5">
          {filteredData?.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" className="table-heading">
                    Sr No.
                  </th>
                  <th scope="col" className="table-heading">
                    Order ID
                  </th>
                  <th scope="col" className="table-heading">
                    Report Type
                  </th>
                  <th scope="col" className="table-heading">
                    Report Description
                  </th>
                  <th scope="col" className="table-heading">
                    Reported By
                  </th>
                  <th scope="col" className="table-heading">
                    Reported On
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredData?.map((data, ind) => (
                  <tr
                    onClick={() => linkToIssue(data.is_ord_id)}
                    state={data.is_ord_id}
                    style={{ cursor: "pointer" }}
                    key={ind}
                  >
                    <td>{ind + 1}</td>
                    <td>{data.is_ord_id}</td>
                    <td>{data.is_report_type}</td>
                    <td>{data.is_report_description}</td>
                    <td>{data.inst_name}</td>
                    <td>
                      {new Date(data.created_at).toLocaleDateString("en-IN", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-center no-data-msg">No data found</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default IssuesList;
