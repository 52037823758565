import React, { useEffect, useState } from "react";
import "../Assets/Css/AllOrders.css";
import useFetchAPI from "../Custom Hooks/useFetchAPI";
import prev from "../Assets/Images/Common/prev_icon.png";
import next from "../Assets/Images/Common/next_icon.png";
// import downArrow from "../Assets/Images/Common/down_arrow.png";
// import upArrow from "../Assets/Images/Common/up_arrow.png";
import { ALL_ORDER_LIST_API } from "../Components/AllApis";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ExportData from "../Components/ExportData";
import Loader from "../Components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

const AllOrders = () => {
  const orderStatus = [
    "All",
    "New",
    "OnGoing",
    "Warehouse",
    "Dispatch",
    "OutForDelivery",
    "Delivered",
    "LabSetup",
    "Complete",
  ];

  const [active, setActive] = useState(
    localStorage.getItem("activeTab")
      ? localStorage.getItem("activeTab")
      : "All"
  );
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  // const [sortBy, setSortBy] = useState(null);
  // const [sortDirection, setSortDirection] = useState("asc");
  const { data, error, loading, triggerFetch } = useFetchAPI(
    ALL_ORDER_LIST_API,
    "GET"
  );
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    triggerFetch();
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    if (data && data.allOrders) {
      filterOrders(active);
    }
  }, [data, active]);

  const filterOrders = (status) => {
    setActive(status);
    localStorage.setItem("activeTab", active);
    if (status === "All") {
      setFilteredOrders(data?.allOrders);
    } else {
      const filtered = data?.allOrders?.filter(
        (order) => order.ord_status === status
      );
      setFilteredOrders(filtered);
    }
  };

  const redirectFromStatus = (order) => {
    if (order === "OnGoing") {
      return "/survey-submission";
    } else if (order === "Warehouse") {
      return "/add-product";
    } else if (order === "Dispatch") {
      return "/order-dispatch";
    } else if (order === "OutForDelivery") {
      return "/view-order";
    } else if (order === "Complete") {
      return "/view-school";
    } else if (order === "Delivered") {
      return "/delivery-confirmation";
    } else if (order === "LabSetup") {
      return "/verify-lab-setup";
    } else {
      return "/all-orders";
    }
  };

  const transformedData =
    filteredOrders?.map((order) => ({
      "Order ID": order?.ord_id,
      "School Name": order?.ord_school_name,
      Installer: order?.ord_installer,
      Receiver: order?.ord_poc_name,
      Status: order?.ord_status,
    })) || [];

  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const totalPages = Math.ceil(filteredOrders?.length / itemsPerPage);
  const totalItems = filteredOrders?.length;
  const pageCount = Math.ceil(totalItems / itemsPerPage);
  const PagePerItem = Math.min(totalItems - indexOfFirstItem, indexOfLastItem);
  const rangeStart = Math.min((currentPage - 1) * itemsPerPage + 1, totalItems);
  const rangeEnd = Math.min(currentPage * itemsPerPage, totalItems);

  // const handleSort = (column) => {
  //   if (sortBy === column) {
  //     setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  //   } else {
  //     setSortBy(column);
  //     setSortDirection("asc");
  //   }
  // };

  // const sortData = (a, b) => {
  //   const keyA =
  //     typeof a[sortBy] === "string" ? a[sortBy].toLowerCase() : a[sortBy];
  //   const keyB =
  //     typeof b[sortBy] === "string" ? b[sortBy].toLowerCase() : b[sortBy];

  //   if (!isNaN(keyA) && !isNaN(keyB)) {
  //     return sortDirection === "asc" ? keyA - keyB : keyB - keyA;
  //   }

  //   if (sortDirection === "asc") {
  //     return keyA.localeCompare(keyB);
  //   } else {
  //     return keyB.localeCompare(keyA);
  //   }
  // };

  const searchedData = filteredOrders?.filter((order) => {
    const query = searchQuery.toLowerCase();
    return (
      order.ord_id.toString().toLowerCase().includes(query) ||
      order.ord_school_name.toLowerCase().includes(query) ||
      order.installer_name.toLowerCase().includes(query) ||
      order.ord_poc_name.toLowerCase().includes(query)
    );
  });

  // const sortedData = sortBy
  //   ? searchedData
  //       ?.slice(indexOfFirstItem, indexOfLastItem)
  //       ?.sort((a, b) => sortData(a, b))
  //   : searchedData?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(Number(pageNumber));
  };

  const nextPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage + 1), pageCount);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const navigateToEditSchool = (order_id) => {
    navigate("/edit-school", { state: order_id });
  };

  const latestSortedData = searchedData?.sort((a, b) => {
    return new Date(b.updated_at) - new Date(a.updated_at);
  });

  const pagindatedData = latestSortedData?.slice(indexOfFirstItem,indexOfLastItem)
  const totalItemtoDisplay = pagindatedData?.length;
  const getStatusBgColor = (status) => {
    switch (status) {
      case "Complete":
        return "rgba(8, 255, 18, 0.3)";
      case "Warehouse":
        return "rgba(255, 236, 189, 0.3)";
      case "Dispatch":
        return "rgba(255, 248, 179, 0.3)";
      case "OutForDelivery":
        return "rgba(255, 189, 189, 0.3)";
      case "OnGoing":
        return "rgba(179, 217, 255, 0.3)";
      case "New":
        return "rgba(213, 213, 213, 0.3)";
      case "Delivered":
        return "rgba(255, 184, 67, 0.3)";
      case "LabSetup":
        return "rgba(141, 117, 92, 0.3)";
      default:
        return "#FFFFFF";
    }
  };

  // const searchFilteredOrders = latestSortedData?.filter((order) => {
  //   const query = searchQuery.toLowerCase();
  //   return (
  //     order.ord_id.toString().toLowerCase().includes(query) ||
  //     order.ord_school_name.toLowerCase().includes(query) ||
  //     order.installer_name.toLowerCase().includes(query) ||
  //     order.ord_poc_name.toLowerCase().includes(query)
  //   );
  // });

  return (
    <section className="all-orders-section common-section">
      {loading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="all-order-header">
            <p className="common-title my-auto">All Orders</p>
            <div className="buttons d-flex my-auto gap-3">
              {filteredOrders?.length > 0 ? (
                <ExportData data={transformedData} filename="All Orders" />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="search-bar mt-3">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  <img
                    src={require("../Assets/Images/Common/search-icon.png")}
                    alt="search-icon"
                  />
                </span>
              </div>
              <input
                type="search"
                className="form-control"
                placeholder="Search Orders"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
          <div className="order-status-chips d-flex gap-3 my-4">
            {orderStatus?.map((status, ind) => {
              return (
                <p
                  key={ind}
                  onClick={() => filterOrders(status)}
                  style={{
                    backgroundColor: status === active ? "#1a80e6" : "",
                    color: status === active ? "white" : " ",
                  }}
                >
                  {status}
                </p>
              );
            })}
          </div>
          <div className="order-table">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" className="table-heading">
                    Order ID
                  </th>
                  <th
                    scope="col"
                    className="table-heading"
                    // onClick={() => handleSort("ord_school_name")}
                  >
                    School Name
                    {/* <span>
                      <button
                        className="sort_button"
                        type="button"
                        onClick={() => handleSort("ord_school_name")}
                      >
                        <img
                          className="desc"
                          width="40%"
                          src={
                            sortDirection === "asc" &&
                            sortBy === "ord_school_name"
                              ? downArrow
                              : upArrow
                          }
                          alt="Sort Button"
                        />
                      </button>
                    </span> */}
                  </th>
                  <th scope="col" className="table-heading">
                    Installer
                  </th>
                  <th scope="col" className="table-heading">
                    Receiver
                    {/* <span>
                      <button
                        className="sort_button"
                        type="button"
                        onClick={() => handleSort("ord_poc_name")}
                      >
                        <img
                          className="desc"
                          width="40%"
                          src={
                            sortDirection === "asc" && sortBy === "ord_poc_name"
                              ? downArrow
                              : upArrow
                          }
                          alt="Sort Button"
                        />
                      </button>
                    </span> */}
                  </th>
                  <th scope="col" className="table-heading">
                    Status
                  </th>
                  <th scope="col" className="table-heading">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {pagindatedData?.length > 0 ? (
                  pagindatedData?.map((order, ind) => (
                    <tr key={ind}>
                      <td>{order.ord_id}</td>
                      <td>{order.ord_school_name}</td>
                      <td>{order.installer_name}</td>
                      <td>{order.ord_poc_name}</td>
                      <td className="order-status-chips">
                        <span
                          style={{
                            backgroundColor: getStatusBgColor(order.ord_status),
                          }}
                        >
                          {order.ord_status}
                        </span>
                      </td>
                      <td>
                        {order.ord_status === "New" ? (
                          <FontAwesomeIcon
                            icon={faEdit}
                            size="lg"
                            onClick={() => navigateToEditSchool(order.ord_id)}
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <Link
                            to={redirectFromStatus(order.ord_status)}
                            className="view-btn"
                            state={order}
                          >
                            View
                          </Link>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center no-data-msg">
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {pagindatedData?.length > 0 && (
            <div className="pagination row">
              <div className="result_msg col-12 col-lg-7 col-sm-7 col-md-7">
                <p className="result_msg_text" style={{ marginBottom: "0px" }}>
                  Displaying {totalItemtoDisplay} results of {totalItems}
                </p>
              </div>

              <div className="button_module col-12 col-lg-5 col-sm-5 col-md-5 d-flex ">
                <div
                  className="d-flex"
                  style={{
                    alignItems: "center",
                    marginRight: "10px",
                  }}
                >
                  <p className="paginate_msg_text">Page</p>
                  <div>
                    <select
                      className="form-select pageDropdown"
                      value={currentPage}
                      onChange={(e) => handlePageChange(e.target.value)}
                    >
                      {Array?.from({ length: pageCount }, (_, i) => i + 1)?.map(
                        (pageNum) => (
                          <option value={pageNum} key={pageNum}>
                            {pageNum}&nbsp;
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <p className="paginate_msg_text">of {pageCount}</p>
                </div>

                <div className=" d-flex">
                  <button
                    type="button"
                    className="bton"
                    onClick={prevPage}
                    disabled={currentPage === 1}
                  >
                    <img src={prev} alt="prev" className="arrow_btn" />
                  </button>

                  <button
                    type="button"
                    className="bton"
                    onClick={nextPage}
                    disabled={currentPage === pageCount}
                  >
                    <img src={next} alt="next" className="arrow_btn" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default AllOrders;
