import React, { useEffect, useState } from "react";
import "../Assets/Css/CreateInstaller.css";
import { CREATE_INSTALLER_POST_API } from "../Components/AllApis";
import useFetchAPI from "../Custom Hooks/useFetchAPI";
import Toaster from "../Components/Toaster";
import { useNavigate } from "react-router-dom";

const CreateInstaller = () => {
  const [formData, setFormData] = useState({
    school: "",
    installer: "",
    name: "",
    email: "",
    mobile: "",
    employeeId: "",
    address: "",
  });
  const [errorMsg, setErrorMsg] = useState({});
  const [showToaster, setShowToaster] = useState("");
  const { data, error, loading, triggerFetch } = useFetchAPI(
    CREATE_INSTALLER_POST_API,
    "POST"
  );
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // validateForm();
  };

  const validateForm = () => {
    let formErrors = {};
    const nameRegx = /^[A-Za-z ., &']+$/;
    const mobileRegx = /^[6789][0-9]{9}$/;
    const emailRegx = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9]+[.-][a-zA-Z]+$/;

    if (!formData.name.trim()) {
      formErrors.name = "Please enter a name.";
    } else if (!nameRegx.test(formData.name)) {
      formErrors.name = "Please enter a valid name.";
    }

    if (!formData.email.trim()) {
      formErrors.email = "Please enter an email.";
    } else if (!emailRegx.test(formData.email)) {
      formErrors.email = "Please enter a valid email.";
    }

    if (!formData.mobile.trim()) {
      formErrors.mobile = "Please enter a mobile number.";
    } else if (!mobileRegx.test(formData.mobile)) {
      formErrors.mobile = "Please enter a valid mobile number.";
    }
    if (!formData.employeeId.trim()) {
      formErrors.employeeId = "Please enter an employee id.";
    }
    if (!formData.mobile.trim()) {
      formErrors.mobile = "Please enter a mobile number.";
    }
    if (!formData.address.trim()) {
      formErrors.address = "Please enter address.";
    }
    setErrorMsg(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();
    const bodyData = {
      name: formData.name,
      email: formData.email,
      phone: formData.mobile,
      inst_address: formData.address,
      inst_emp_id: formData.employeeId,
    };
    if (isValid) {
      triggerFetch(bodyData);
    }
  };

  useEffect(() => {
    if (data) {
      console.log("data", data);
      setShowToaster(data);
    }
  }, [data]);
  const onclosefunction = () => {
    navigate("/installer-list");
  };
  return (
    <section>
      <div className="container-fluid createInst">
        <div className="container pt-5 ps-5 pe-5 pb-5">
          <p className="createInst-title">Create an Installer</p>
          <div>
            <form onSubmit={handleSubmit}>
              {/* <p className="createInst-text">Select a School</p>
              <select
                className="form-control createInst-input"
                name="school"
                value={formData.school}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                <option value="school1">school1</option>
              </select>

              <p className="createInst-text">Select an Installer</p>
              <select
                className="form-control createInst-input"
                name="installer"
                value={formData.installer}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                <option value="installer1">installer1</option>
              </select> */}
              <Toaster showToaster={showToaster} onClose={onclosefunction} />
              <p className="createInst-title title-2">Installer Information</p>

              <p className="createInst-text">Name</p>
              <input
                type="text"
                className="form-control createInst-input"
                placeholder="Name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
              {errorMsg.name && (
                <p className="errorMsg-text">{errorMsg.name}</p>
              )}
              <p className="createInst-text">Email</p>
              <input
                type="text"
                className="form-control createInst-input"
                placeholder="Enter Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              {errorMsg.email && (
                <p className="errorMsg-text">{errorMsg.email}</p>
              )}
              <div className="row">
                <div className="col-6">
                  <p className="createInst-text">Mobile number</p>
                  <input
                    type="text"
                    className="form-control createInst-input"
                    placeholder="Mobile"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleInputChange}
                    maxLength={10}
                  />
                  {errorMsg.mobile && (
                    <p className="errorMsg-text">{errorMsg.mobile}</p>
                  )}
                </div>
                <div className="col-6">
                  <p className="createInst-text">Employee ID</p>
                  <input
                    type="text"
                    className="form-control createInst-input"
                    placeholder="Employee ID"
                    name="employeeId"
                    maxLength={10}
                    value={formData.employeeId}
                    onChange={handleInputChange}
                  />
                  {errorMsg.employeeId && (
                    <p className="errorMsg-text">{errorMsg.employeeId}</p>
                  )}
                </div>
              </div>

              <p className="createInst-text">Current Address</p>
              <textarea
                className="form-control createInst-input"
                placeholder="Address"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
              ></textarea>

              <div className="createInst-btn-wrapper mt-5 d-flex gap-4">
                <button
                  type="button"
                  className="btn btn-light createInst-btn"
                  onClick={() => navigate("/installer-list")}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary createInst-btn"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Add Installer"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateInstaller;
