import React from "react";
import "../Assets/Css/TableStructure.css";
const TableStructure = ({ headers, tableData }) => {
  return (
    <section className="table-structure-section">
      <div className="container">
        <div className="order-table mt-5">
          <table className="table ">
            <thead>
              <tr>
                {headers.map((data, ind) => {
                  return (
                    <th scope="col" className="table-heading" key={ind}>
                      {data}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                {tableData.map((data, ind) => {
                  return <td key={ind}>{data}</td>;
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default TableStructure;
