import React from "react";
import { Link } from "react-router-dom";

const GrayButton = (props) => {
  const linkTo = props?.to ?? "";
  const btnName = props.btnName;
  const width = props?.width ?? "";
  const clickHandler = props?.clickHandler ?? "";
  return (
    <>
      <Link
        className={`common-gray-button btn my-auto w-${width}`}
        to={linkTo}
        onClick={clickHandler}
      >
        {btnName}
      </Link>
    </>
  );
};

export default GrayButton;
