import React, { useEffect, useState } from "react";
import BlueButton from "../Components/BlueButton";
import axios from "axios";
import { API_START_POINT } from "../Components/AllApis";
import { useLocation } from "react-router-dom";
import Toaster from "../Components/Toaster";
import useFetchAPI from "../Custom Hooks/useFetchAPI";
import Loader from "../Components/Loader";

const ViewOrder = () => {
  const [orderDetails, setOrderDetails] = useState([]);
  const location = useLocation();
  const order_id = location?.state?.ord_id;
  const { data, error, loading, triggerFetch } = useFetchAPI(
    `${API_START_POINT}/getRecieverDetails/${order_id}`,
    "GET"
  );
  useEffect(() => {
    triggerFetch();
  }, []);

  useEffect(() => {
    if (data && data.order) {
      setOrderDetails(data.order);
    }
  }, [data]);
  return (
    <>
      <section className="common-section">
        {loading ? (
          <Loader />
        ) : (
          <div className="container w-75">
            <div className="w-50 m-auto">
              <div className="d-flex justify-content-between">
                <p className="createInst-title">Delivery Details</p>
                <BlueButton
                  btnName="Edit Order"
                  to="/edit-school"
                  state={order_id}
                />
              </div>
              <form>
                <p className="createInst-text">Type of Receiver</p>
                <input
                  type="text"
                  className="form-control createInst-input"
                  placeholder="Receiver type"
                  value={orderDetails.ord_disp_receiver_type}
                  readOnly
                />
                <p className="createInst-text">Name</p>
                <input
                  type="text"
                  className="form-control createInst-input"
                  placeholder="Name"
                  name="name"
                  value={orderDetails.ord_disp_receiver_name}
                  readOnly
                />

                <p className="createInst-text">Mobile number</p>
                <input
                  type="text"
                  className="form-control createInst-input"
                  placeholder="Mobile"
                  name="mobile"
                  maxLength={10}
                  value={orderDetails.ord_disp_receiver_phone}
                  readOnly
                />

                <p className="createInst-text">Email Id</p>
                <input
                  type="text"
                  className="form-control createInst-input"
                  placeholder="Email Id"
                  name="email"
                  value={orderDetails.ord_disp_receiver_email}
                  readOnly
                />

                <p className="createInst-text">Delivery details</p>
                <textarea
                  className="form-control createInst-input"
                  placeholder="Delivery details"
                  name="deliveryDetails"
                  value={orderDetails.ord_disp_delivery_details}
                  readOnly
                ></textarea>
              </form>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default ViewOrder;
