import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import "../Assets/Css/Navbar.css";
import BlueButton from "./BlueButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const [showProfile, setShowProfile] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const profileRef = useRef(null);
  const profileIconRef = useRef(null);

  const viewProfile = () => {
    setShowProfile((prev) => !prev);
  };

  const logoutHandler = () => {
    localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileRef.current &&
        !profileRef.current.contains(event.target) &&
        !profileIconRef.current.contains(event.target)
      ) {
        setShowProfile(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        hideModal();
      }
    };
    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  useEffect(() => {
    setShowProfile(false);
  }, [location]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  const handleLogoutConfirm = () => {
    logoutHandler();
    hideModal();
  };

  const isActivePath = (pathList) => {
    return pathList.some((path) => location.pathname.startsWith(path));
  };

  return (
    <section
      className="navbar-section fixed-top"
      style={{ backgroundColor: "white" }}
    >
      <div className="nav-logo">
        <img
          src={require("../Assets/Images/Common/phn-lab-logo.png")}
          alt=""
          className="nav-phn-logo"
        />
        {/* PHN */}
      </div>
      <div className="navs-tab d-flex gap-4 ">
        <NavLink
          className="navs-links my-auto"
          to="/dashboard"
          isActive={() => location.pathname === "/dashboard"}
        >
          Dashboard
        </NavLink>
        <NavLink className={`navs-links my-auto ${isActivePath(["/create-installer"])?"active":""}`} to="/installer-list">
          Installers
        </NavLink>
        <NavLink
          // className="navs-links my-auto"
          // to="/all-orders"
          className={`navs-links my-auto ${
            isActivePath([
              "/view-school",
              "/survey-submission",
              "/verify-lab-setup",
              "/view-order",
              "/order-dispatch",
              "/add-product",
              "/edit-school",
              "/add-school",
              "/all-orders",
              "/create-product",
            ])
              ? "active"
              : ""
          }`}
          to="/all-orders"
        >
          Orders
        </NavLink>
        <NavLink
          className={`navs-links my-auto ${ 
            isActivePath(["/activities"]) ? "active" : ""
          }`}
          to="/activities"
        >
          Activities
        </NavLink>
        <NavLink
          className={`navs-links my-auto ${
            isActivePath(["/issues-list", "/issue-reported"]) ? "active" : ""
          }`}
          to="/issues-list"
        >
          Issues
        </NavLink>
      </div>
      <div className="nav-buttons d-flex gap-4">
        <BlueButton to="/add-school" btnName="Add Order" />
        <Link>
          <img
            src={require("../Assets/Images/Navbar/notification-icon.png")}
            alt="notification-icon"
            className="mt-2"
          />
        </Link>
        <div
          className="user-profile my-auto"
          onClick={viewProfile}
          style={{ cursor: "pointer" }}
          ref={profileIconRef}
        >
          <img
            src={
              require("../Assets/Images/Common/profile_placeholder_img.svg")
                .default
            }
            alt="user-profile-icon"
            width="43px"
          />
        </div>
        {showProfile && (
          <div className="profile-list card " ref={profileRef}>
            <div className="nav-profile-list-one card-body ">
              <Link
                to="/profile"
                style={{
                  textDecoration: "none",
                  color: "black",
                  display: "flex",
                }}
              >
                <div>
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <p className="nav-profile-text">View Profile</p>
              </Link>
            </div>
            <div
              className="nav-profile-list-two card-body  d-flex"
              style={{ cursor: "pointer" }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faArrowRightFromBracket}
                  onClick={showModal}
                />
              </div>
              <p
                className="nav-profile-text"
                onClick={showModal}
                style={{ cursor: "pointer" }}
              >
                Logout
              </p>
            </div>
          </div>
        )}

        {isModalVisible && (
          <div
            className="modal fade show logout-modal"
            tabIndex="-1"
            style={{
              display: "block",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
            aria-labelledby="LogoutModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content card">
                <div className="card-body">
                  <div className="modal-header">
                    <h5 className="modal-title" id="LogoutModalLabel">
                      Log out
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={hideModal}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <p className="nav-modal-body-text">
                      Are you sure you want to Log out?
                    </p>
                    <div className="d-flex justify-content-center mt-4 gap-5">
                      <BlueButton
                        btnName="Yes"
                        clickHandler={handleLogoutConfirm}
                      />
                      <button
                        type="button"
                        className="btn btn-secondary nav-logout-no-btn"
                        onClick={hideModal}
                      >
                        {" "}
                        No{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Navbar;
