import React, { useEffect, useState } from "react";
import GrayButton from "../Components/GrayButton";
import useFetchAPI from "../Custom Hooks/useFetchAPI";
import { useLocation, useNavigate } from "react-router-dom";
import { API_START_POINT } from "../Components/AllApis";
import { PhotoProvider, PhotoView } from "react-photo-view";
import Loader from "../Components/Loader";

const IssueReported = () => {
  const [issues, setIssues] = useState([]);
  const location = useLocation();
  const order_id = location?.state;
  const { data, error, loading, triggerFetch } = useFetchAPI(
    `${API_START_POINT}/getIssueReportDetails/${order_id}`,
    "GET"
  );

  useEffect(() => {
    triggerFetch();
  }, []);

  useEffect(() => {
    if (data) {
      setIssues(data?.issue_report);
    }
  }, [data]);
  return (
    <section className="common-section Issue-reported-section centered-section">
      {loading ? (
        <Loader />
      ) : (
        <div className="container w-50">
          <p className="common-title mt-5" style={{ marginLeft: "-14px" }}>
            Issue Reported
          </p>
          <div className="centerd-section-details">
            <p className="common-sub-title mt-4">Description</p>
            <p className="common-description">
              {issues?.is_report_description
                ? issues?.is_report_description
                : "-"}
            </p>
            <p className="common-sub-title mt-5">Type</p>
            <div className="d-flex gap-4">
              <img
                src={require("../Assets/Images/Common/maintenance-img.png")}
                alt="maintenance-img"
              />

              <p className="common-description my-auto ">
                {issues?.is_report_type ? issues.is_report_type : "-"}
              </p>
            </div>
            <p className="common-sub-title mt-5">School</p>
            <div className="d-flex gap-4">
              <img
                src={require("../Assets/Images/Common/high-school-img.png")}
                alt="high-school-img"
              />
              <p className="common-description  my-auto ">
                {issues?.school_name ? issues?.school_name : "-"}
              </p>
            </div>
            <p className="common-sub-title mt-5">Reported By</p>
            <p className="common-description">
              {issues?.installer_name ? issues?.installer_name : "-"}
            </p>
            <p className="common-sub-title mt-5">Photos</p>
            {issues?.is_report_images?.length > 0 ? (
              <PhotoProvider>
                <div className="row">
                  {issues?.is_report_images?.map((val, ind) => (
                    <div className="col-md-4" key={ind}>
                      <PhotoView src={val}>
                        <img
                          src={val}
                          alt=""
                          height="250px"
                          style={{ cursor: "pointer" }}
                        />
                      </PhotoView>
                    </div>
                  ))}
                </div>
              </PhotoProvider>
            ) : (
              <p>No Photos Available</p>
            )}
            {/* <div className="mt-5">
            <GrayButton btnName="Close Issue" width="75" />
          </div>
          <p className="common-description mt-3" style={{ marginLeft: "25%" }}>
            Issue reported 1 week ago
          </p> */}
          </div>
        </div>
      )}
    </section>
  );
};

export default IssueReported;
