import React, { useEffect } from "react";
import Overview from "./Overview";
import SchoolsList from "./SchoolsList";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  return (
    <section className="common-section">
      <Overview />
      <SchoolsList />
    </section>
  );
};

export default Dashboard;
