import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import Navbar from "./Components/Navbar";
import Activities from "./Pages/Activities";
import Dashboard from "./Pages/Dashboard";
import OrderDispatch from "./Pages/OrderDispatch";
import AllOrders from "./Pages/AllOrders";
import Footer from "./Components/Footer";
import AddSchool from "./Pages/AddSchool";
import Notifications from "./Pages/Notifications";
import SurveySubmission from "./Pages/SurveySubmission";
import IssueReported from "./Pages/IssueReported";
import DeliveryConfirmation from "./Pages/DeliveryConfirmation";
import IssuesList from "./Pages/IssuesList";
import VerifyLabSetup from "./Pages/VerifyLabSetup";
import SchoolsList from "./Pages/SchoolsList";
import Login from "./Components/Login";
import ResetPass from "./Components/ResetPassword";
import CreateInstaller from "./Pages/CreateInstaller";
import InstallerList from "./Pages/InstallerList";
import EditSchool from "./Pages/EditSchool";
import AddNewItem from "./Components/AddNewItem";
import AddProduct from "./Components/AddProduct";
import SubmitOrder from "./Pages/SubmitOrder";
import ViewSchool from "./Pages/ViewSchool";
import ViewOrder from "./Pages/ViewOrder";
import Profile from "./Components/Profile";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

function App() {
  const location = useLocation();
  const hideNavbarFooter =
    location.pathname === "/" ||
    location.pathname === "/reset-password" ||
    location.pathname === "/privacy-policy";

  return (
    <div className="App">
      {!hideNavbarFooter && <Navbar />}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/reset-password" element={<ResetPass />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/schools-list" element={<SchoolsList />} />
        <Route path="/order-dispatch" element={<OrderDispatch />} />
        <Route path="/all-orders" element={<AllOrders />} />
        <Route path="/add-school" element={<AddSchool />} />
        <Route path="/edit-school" element={<EditSchool />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/survey-submission" element={<SurveySubmission />} />
        <Route path="/issue-reported" element={<IssueReported />} />
        <Route path="/issues-list" element={<IssuesList />} />
        <Route path="/verify-lab-setup" element={<VerifyLabSetup />} />
        <Route path="/create-installer" element={<CreateInstaller />} />
        <Route path="/installer-list" element={<InstallerList />} />
        <Route path="/create-product" element={<AddNewItem />} />
        <Route path="/add-product" element={<AddProduct />} />
        <Route path="/submit-order" element={<SubmitOrder />} />
        <Route path="/view-school" element={<ViewSchool />} />
        <Route path="/view-order" element={<ViewOrder />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/delivery-confirmation"
          element={<DeliveryConfirmation />}
        />
        <Route path="/activities" element={<Activities />} />
      </Routes>
      {!hideNavbarFooter && <Footer />}
    </div>
  );
}

export default App;