import React from "react";
import { FadeLoader } from "react-spinners";

const Loader = () => {
  return (
    <div className="loader">
      <FadeLoader
        color="blue"
        size={160}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Loader;
