import React, { useEffect, useState } from "react";
import "../Assets/Css/AddSchool.css";
import useFetchAPI from "../Custom Hooks/useFetchAPI";
import {
  CREATE_ORDER_GET_INSTALLER_LIST_API,
  API_START_POINT,
} from "../Components/AllApis";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import stateanddistricts from "../Assets/Json/StateAndDistricts.json";
import { useLocation } from "react-router-dom";
import Loader from "../Components/Loader";
import BlueButton from "../Components/BlueButton";

const ViewSchool = () => {
  const location = useLocation();
  const order_id = location?.state?.ord_id;
  const [preData, setPreData] = useState(null);
  console.log(preData);
  const [pdfUrl, setPdfUrl] = useState("");
  const { data, error, loading, triggerFetch } = useFetchAPI(
    `${API_START_POINT}/get-order-details/${order_id}`,
    "GET"
  );

  useEffect(() => {
    triggerFetch();
  }, []);

  useEffect(() => {
    if (data && data.order) {
      setPreData(data.order);
    }
  }, [data]);
  const token = localStorage.getItem("authToken");
  const downloadPdf = async () => {
    try {
      const response = await fetch(
        `${API_START_POINT}/orders/${order_id}/products/pdf`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log(data);
      setPdfUrl(data.pdf_url);
      return data.allProducts || [];
    } catch (error) {
      console.error("Error fetching products:", error);

      return [];
    }
  };
  useEffect(() => {
    downloadPdf();
  }, []);

  const onButtonClick = () => {
    fetch(pdfUrl).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "order_details.pdf";
        alink.click();
      });
    });
  };
  return (
    <section className="common-section">
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid addSchool">
          <div className="container ps-5 pe-5 pb-5">
            <div className="d-flex justify-content-between">
              <p className="addSchool-title"> Order Details</p>
            </div>
            <div>
              <div className="d-flex justify-content-between mt-3">
                <p className="common-sub-title">School Contact Details</p>
                <div>
                  <button
                    className="btn btn-outline-primary ext-light me-3"
                    onClick={() => onButtonClick()}
                    download
                  >
                    Download Pdf
                  </button>

                  <button className="btn btn-success">Order completed</button>
                </div>
              </div>

              <form action="">
                <p className="addSchool-text">School Name</p>
                <input
                  type="text"
                  className="form-control addSchool-input"
                  placeholder="Enter the School name"
                  readOnly
                  value={preData?.ord_school_name}
                  name="school_name"
                />

                <p className="addSchool-text">Address</p>
                <input
                  type="text"
                  className="form-control addSchool-input"
                  placeholder="Enter the Address"
                  readOnly
                  value={preData?.ord_school_address}
                  name="address"
                />

                <div className="row">
                  <div className="col-6">
                    <p className="addSchool-text">State</p>
                    <select
                      className="form-control addSchool-input"
                      readOnly
                      value={preData?.ord_school_state}
                      name="state"
                    >
                      <option readOnly value={preData?.ord_school_state}>
                        {preData?.ord_school_state}
                      </option>
                    </select>
                  </div>
                  <div className="col-6">
                    <p className="addSchool-text">District</p>
                    <select
                      className="form-control addSchool-input"
                      readOnly
                      value={preData?.district}
                      name="district"
                    >
                      <option readOnly value={preData?.ord_school_district}>
                        {preData?.ord_school_district}
                      </option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="addSchool-text">City</p>
                    <input
                      type="text"
                      className="form-control addSchool-input"
                      placeholder="Enter the City"
                      readOnly
                      value={preData?.ord_school_city}
                      name="city"
                    />
                  </div>
                  <div className="col-6">
                    <p className="addSchool-text">Board</p>
                    <select
                      className="form-control addSchool-input"
                      readOnly
                      value={preData?.ord_school_board}
                      name="board"
                    >
                      <option readOnly value="">
                        {preData?.ord_school_board}
                      </option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="addSchool-text">School Email ID</p>
                    <input
                      type="text"
                      className="form-control addSchool-input"
                      placeholder="Enter School Email ID"
                      readOnly
                      value={preData?.ord_school_email}
                      name="school_email_id"
                    />
                  </div>
                  <div className="col-6">
                    <p className="addSchool-text">Pincode</p>
                    <input
                      type="text"
                      className="form-control addSchool-input"
                      placeholder="Enter Pincode"
                      readOnly
                      value={preData?.ord_school_zipcode}
                      name="pincode"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <p className="addSchool-text">Type of Lab</p>
                    <select
                      className="form-control addSchool-input"
                      readOnly
                      value={preData?.ord_school_lab_type}
                      name="labtype"
                    >
                      <option readOnly value="">
                        {preData?.ord_school_lab_type}
                      </option>
                    </select>
                  </div>
                  <div className="col-lg-6">
                    <p className="addSchool-text">Installer</p>
                    <select
                      className="form-control addSchool-input"
                      placeholder="Enter the Address"
                      readOnly
                      value={preData?.inst_name}
                      name="installer"
                    >
                      <option readOnly value="">
                        {preData?.inst_name}
                      </option>
                    </select>
                  </div>
                </div>
                <p className="common-sub-title mt-4">
                  Principal Contact Details
                </p>
                <div className="row">
                  <div className="col-6">
                    <p className="addSchool-text">Principal Name</p>
                    <input
                      type="text"
                      className="form-control addSchool-input"
                      placeholder="Enter Principal Name"
                      readOnly
                      value={preData?.ord_princi_name}
                      name="principal_name"
                    />
                  </div>
                  <div className="col-6">
                    <p className="addSchool-text">Principal Mobile No</p>
                    <input
                      type="text"
                      className="form-control addSchool-input"
                      placeholder="Enter Principal Mobile No"
                      readOnly
                      value={preData?.ord_princi_phone}
                      name="principal_mobile_no"
                      maxLength={10}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="addSchool-text">Prinicipal Email ID</p>
                    <input
                      type="text"
                      className="form-control addSchool-input"
                      placeholder="Enter Prinicipal Email ID"
                      readOnly
                      value={preData?.ord_princi_email}
                      name="principal_email_id"
                    />
                  </div>
                </div>

                <div className="checkbox d-flex gap-4 mt-4"></div>
                <div className="row">
                  <div className="col-6">
                    <p className="addSchool-text">POC Name</p>
                    <input
                      type="text"
                      className="form-control addSchool-input"
                      placeholder="Enter POC  Name"
                      readOnly
                      value={preData?.ord_poc_name}
                      name="poc_name"
                    />
                  </div>
                  <div className="col-6">
                    <p className="addSchool-text">POC Mobile No</p>
                    <input
                      type="text"
                      className="form-control addSchool-input"
                      placeholder="Enter POC Mobile No"
                      readOnly
                      value={preData?.ord_poc_phone}
                      name="poc_mobile_no"
                      maxLength={10}
                    />
                  </div>
                  <div className="col-6">
                    <p className="addSchool-text">POC Email ID</p>
                    <input
                      type="text"
                      className="form-control addSchool-input"
                      placeholder="Enter POC Email ID"
                      readOnly
                      value={preData?.ord_poc_email}
                      name="poc_email_id"
                    />
                  </div>
                </div>
                <p className="common-sub-title mt-4">Survey Date & Time</p>

                <div className="row">
                  <div className="col-lg-6">
                    <p className="addSchool-text">Date</p>
                    <input
                      type={
                        preData?.ord_surv_date === null ||
                        preData?.ord_surv_date === ""
                          ? "date"
                          : "text"
                      }
                      className="form-control addSchool-input"
                      name="date"
                      readOnly
                      value={preData?.ord_surv_date}
                    />
                  </div>
                  <div className="col-lg-6">
                    <p className="addSchool-text">Time</p>
                    <input
                      type="time"
                      className="form-control addSchool-input"
                      name="time"
                      readOnly
                      value={preData?.ord_surv_time}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default ViewSchool;
