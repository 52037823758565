import React from "react";

const Footer = () => {
  return (
    <section className="mt-5 footer-section border-top">
      <div className="container pt-3">
        <div className="d-flex justify-content-between">
          <p className="footer-title">2024 PHN Technologies Pvt Ltd.</p>
          <div className="d-flex gap-5">
            <p className="footer-title">Privacy Policy</p>
            <p className="footer-title">Terms of Service</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
