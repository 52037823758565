import React, { useEffect, useState } from "react";
import "../Assets/Css/SchoolsList.css";
import axios from "axios";
import useFetchAPI from "../Custom Hooks/useFetchAPI";
import { DASHBOARD_SCHOOL_LIST } from "../Components/AllApis";

const SchoolsList = () => {
  const [schoolData, setSchoolData] = useState([]);

  const { data, error, loading, triggerFetch } = useFetchAPI(
    DASHBOARD_SCHOOL_LIST,
    "GET"
  );

  useEffect(() => {
    triggerFetch();
  }, []);

  useEffect(() => {
    if (data) {
      // console.log(data.allOrders);
      setSchoolData(data.allOrders);
    }
  }, [data]);

  // Function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
  };
  const getStatusBgColor = (status) => {
    switch (status) {
      case "Complete":
        return "rgba(8, 255, 18, 0.3)";
      case "Warehouse":
        return "rgba(255, 236, 189, 0.3)";
      case "Dispatch":
        return "rgba(255, 248, 179, 0.3)";
      case "OutForDelivery":
        return "rgba(255, 189, 189, 0.3)";
      case "OnGoing":
        return "rgba(179, 217, 255, 0.3)";
      case "New":
        return "rgba(213, 213, 213, 0.3)";
      case "Delivered":
        return "rgba(255, 184, 67, 0.3)";
      case "LabSetup":
        return "rgba(141, 117, 92, 0.3)";
      default:
        return "#FFFFFF"; 
    }
  };
  return (
    <section className="schools-list-section mt-5">
      <div className="container">
        <p className="common-title">Orders List</p>
        <table className="table table-borderless">
          <tbody>
            {schoolData.slice(0, 4).map((data, ind) => (
              <tr key={ind}>
                <td style={{ width: "7%" }}>
                  <img
                    src={require("../Assets/Images/Common/schools-logo.png")}
                    alt="schools-logo"
                  />
                </td>
                <td>
                  <p className="school-name mb-0 mt-2">
                    {data.ord_school_name}
                  </p>
                  <p className="updated-date">
                    {data.installer_name} Updated at{" "}
                    {formatDate(data.updated_at)}
                  </p>
                </td>
                <td>
                  <p
                    className="status-title mt-3"
                    style={{
                      backgroundColor: getStatusBgColor(data.ord_status),
                    }}
                  >
                    {data.ord_status}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default SchoolsList;
