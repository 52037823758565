import React from "react";
import TableStructure from "../Components/TableStructure";

const Notifications = () => {
  const headers = [
    "Sr No.",
    "Report Issue",
    "School Name",
    "Reported By",
    "Order ID",
  ];
  const data = [1, "marks", "otto", "mdo", "mdo"];
  return (
    <section className="notifications-section common-section">
      <div className="container">
        <p className="common-title">Notifications</p>
        <div className="search-bar mt-3">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                <img
                  src={require("../Assets/Images/Common/search-icon.png")}
                  alt="search-icon"
                />
              </span>
            </div>
            <input
              type="search"
              className="form-control"
              placeholder="Search for issues"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>
        </div>
        <p className="common-sub-title mt-5">Recent Issues</p>
        <TableStructure headers={headers} tableData={data} />
      </div>
    </section>
  );
};

export default Notifications;
