import React, { useEffect, useState } from "react";
import BlueButton from "../Components/BlueButton";
import axios from "axios";
import {
  API_START_POINT,
  CREATE_ORDER_GET_INSTALLER_LIST_API,
} from "../Components/AllApis";
import { useLocation, useNavigate } from "react-router-dom";
import Toaster from "../Components/Toaster";

const SubmitOrder = () => {
  const [submitOrderDetails, setsubmitOrderDetails] = useState({
    typeOfReceiver: "",
    installerName: "",
    name: "",
    mobile: "",
    email: "",
    deliveryDetails: "",
  });
  const [errors, setErrors] = useState({});
  const [showInstallerDrp, setShowInstallerDrp] = useState(false);
  const [installers, setInstallers] = useState([]);
  const location = useLocation();
  const order_id = location.state;
  console.log(order_id);
  const [showModal, setShowModal] = useState(false);
  const [completionSuccess, setCompletionSuccess] = useState(false);
  const [showToaster, setShowToaster] = useState({
    message: "",
    show: false,
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (submitOrderDetails.typeOfReceiver === "Installer") {
      setShowInstallerDrp(true);
    } else {
      setShowInstallerDrp(false);
    }
  }, [submitOrderDetails.typeOfReceiver]);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    axios
      .get(CREATE_ORDER_GET_INSTALLER_LIST_API, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setInstallers(response.data.allInstallers);
        console.log("data: ", response.data.allInstallers);
      })
      .catch(function (error) {
        console.error("Error fetching data:", error);
      });
  }, []);

  const validateData = () => {
    let dataErrors = {};
    const nameRegx = /^[A-Z a-z . , & ']+$/;
    const mobileRegx = /^[6789][0123456789]{9}$/;
    const emailRegx = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9]+[.-][a-zA-Z][a-z.A-Z]+$/;

    if (!submitOrderDetails.email) {
      dataErrors.email = "Please enter the email address.";
    } else if (!emailRegx.test(submitOrderDetails.email)) {
      dataErrors.email = "Please enter a valid email address.";
    }
    if (!submitOrderDetails.mobile) {
      dataErrors.mobile = "Please enter the mobile no.";
    } else if (!mobileRegx.test(submitOrderDetails.mobile)) {
      dataErrors.mobile = "Please enter a valid mobile no.";
    }
    if (!submitOrderDetails.name) {
      dataErrors.name = "Please enter the name.";
    } else if (!nameRegx.test(submitOrderDetails.name)) {
      dataErrors.name = "Please enter a valid name.";
    }

    if (!submitOrderDetails.typeOfReceiver) {
      dataErrors.typeOfReceiver = "Please select type of receiver.";
    }

    if (showInstallerDrp && !submitOrderDetails.installerName) {
      dataErrors.installerName = "Please select installer name.";
    }
    if (!submitOrderDetails.deliveryDetails) {
      dataErrors.deliveryDetails = "Please enter delivery details.";
    }
    setErrors(dataErrors);
    return Object.keys(dataErrors).length === 0;
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;

    setsubmitOrderDetails({
      ...submitOrderDetails,
      [name]: value,
    });

    if (name === "installerName") {
      const selectedInstaller = installers.find(
        (installer) => String(installer.inst_id) === String(value)
      );

      if (selectedInstaller) {
        setsubmitOrderDetails({
          ...submitOrderDetails,
          installerName: selectedInstaller.inst_id,
          name: selectedInstaller.inst_name,
          mobile: selectedInstaller.inst_phone,
          email: selectedInstaller.inst_email,
        });
      }
    }

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const token = localStorage.getItem("authToken");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateData()) {
      setErrors({});
      axios({
        method: "POST",
        url: `${API_START_POINT}/mgrApproveDispatch/${order_id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ord_disp_delivery_details: submitOrderDetails.deliveryDetails,
          ord_disp_receiver_type: submitOrderDetails.typeOfReceiver,
          ord_disp_receiver_inst_id: submitOrderDetails.installerName,
          ord_disp_receiver_name: submitOrderDetails.name,
          ord_disp_receiver_email: submitOrderDetails.email,
          ord_disp_receiver_phone: submitOrderDetails.mobile,
        },
      })
        .then(function (response) {
          console.log(response.data);
          setShowToaster({
            message: "Order dispatched successfully!",
            show: true,
          });
          setShowModal(false);
          setCompletionSuccess(true);
        })
        .catch(function (error) {
          setShowToaster({
            message: "something went wrong,Please try again later.",
            show: true,
          });
          setShowModal(false);
        });
    }
  };
  const onclosefunction = () => {
    setShowToaster({ ...showToaster, show: false });
    if (completionSuccess) {
      navigate("/all-orders");
    }
  };
  return (
    <>
      <section className="common-section">
        <div className="container w-50">
          <div className="w-50 m-auto">
            {showToaster.show && (
              <Toaster showToaster={showToaster} onClose={onclosefunction} />
            )}
            <p className="createInst-title">Submit Delivery Details</p>
            <form>
              {/* <Toaster showToaster={showToaster} /> */}

              <p className="createInst-text">Type of Receiver</p>
              <select
                className="form-control addSchool-input"
                name="typeOfReceiver"
                onChange={changeHandler}
                value={submitOrderDetails.typeOfReceiver}
              >
                <option value="">Select</option>
                <option value="Installer">Installer</option>
                <option value="Customer">Customer</option>
              </select>
              {errors.typeOfReceiver && (
                <p className="error-text">{errors.typeOfReceiver}</p>
              )}
              {showInstallerDrp && (
                <>
                  <p className="createInst-text">Installer Name</p>
                  <select
                    className="form-control addSchool-input"
                    name="installerName"
                    onChange={changeHandler}
                    value={submitOrderDetails.installerName}
                  >
                    <option value="">Select</option>
                    {installers.map((data, ind) => {
                      return (
                        <option value={data.inst_id} key={ind}>
                          {data.inst_name}
                        </option>
                      );
                    })}
                  </select>
                  {errors.installerName && (
                    <p className="error-text">{errors.installerName}</p>
                  )}
                </>
              )}

              <p className="createInst-text">Name</p>
              <input
                type="text"
                className="form-control createInst-input"
                placeholder="Name"
                name="name"
                onChange={changeHandler}
                value={submitOrderDetails.name}
              />
              {errors.name && <p className="error-text">{errors.name}</p>}
              <p className="createInst-text">Mobile number</p>
              <input
                type="text"
                className="form-control createInst-input"
                placeholder="Mobile"
                name="mobile"
                maxLength={10}
                onChange={changeHandler}
                value={submitOrderDetails.mobile}
              />
              {errors.mobile && <p className="error-text">{errors.mobile}</p>}

              <p className="createInst-text">Email ID</p>
              <input
                type="text"
                className="form-control createInst-input"
                placeholder="Email ID"
                name="email"
                onChange={changeHandler}
                value={submitOrderDetails.email}
              />
              {errors.email && <p className="error-text">{errors.email}</p>}

              <p className="createInst-text">Delivery details</p>
              <textarea
                className="form-control createInst-input"
                placeholder="Delivery details"
                name="deliveryDetails"
                onChange={changeHandler}
                value={submitOrderDetails.deliveryDetails}
              ></textarea>
              {errors.deliveryDetails && (
                <p className="error-text">{errors.deliveryDetails}</p>
              )}

              <div className="mt-4">
                <BlueButton
                  btnName="Submit"
                  clickHandler={handleSubmit}
                />
              </div>

              {/* {showModal && (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "36rem",
                  }}
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="btn-close"
                        onClick={() => setShowModal(false)}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p className="common-sub-title">
                        Do you want to submit ?
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary py-2"
                        onClick={() => setShowModal(false)}
                        style={{ borderRadius: "12px" }}
                      >
                        Cancel
                      </button>
                      <BlueButton
                        btnName="Submit"
                        clickHandler={handleSubmit}
                      />
                    </div>
                  </div>
                </div>
              )} */}
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default SubmitOrder;
