import React, { useState } from "react";
import "../Assets/Css/ResetPassword.css";
import { Link } from "react-router-dom";

const ResetPass = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const validateEmail = () => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (email === "") {
      setError("Please enter a email address");
    } else if (!validateEmail(email)) {
      setError("Please enter a valid email address");
    }
  };

  return (
    <section className="reset-pass">
      <div
        className="p-5"
        style={{
          boxShadow: "rgba(100, 100, 111, 0.2) 1px 1px 3px 3px",
          borderRadius: "20px",
        }}
      >
        <div className="">
          <p className="resetpass-title">Reset your password</p>
          <p className="resetpassText">
            Enter your email and we'll send you a link to reset your password.
          </p>
          <form action="" onSubmit={handleSubmit}>
            <p className="resetpass-inputText">Email ID</p>
            <input
              type="text"
              className="form-control resetPassInput"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
            />
            {error && <p className="error-text">{error}</p>}
            <input
              type="submit"
              value="Send Reset Link"
              className="w-100 btn login-btn"
            />
          </form>
          <div className="">
            <Link to="/" className="btn btn-light backtoLogin-btn">
              Back to Log In
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPass;
