import React, { useState } from "react";
import axios from "axios";
import "../Assets/Css/AddNewItem.css";
import { CREATE_PRODUCT } from "../Components/AllApis";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddNewItem = () => {
  const [formData, setFormData] = useState({
    prod_sku_no: "",

    prod_name: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  
  const token = localStorage.getItem("authToken"); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("data: ", formData);
    if (!formData.prod_sku_no || !formData.prod_name) {
      toast.error("Please fill in both fields", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return; 
    }
    try {
      const response = await axios.post(CREATE_PRODUCT, formData,{
        headers: {
          Authorization: `Bearer ${token}`, 
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (response.data.message === "Product created successfully") {
        toast.success("Product created successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      console.log("Item added successfully:", response.data);
      
    } catch (error) {
      if (error.response && error.response.status === 409) {
        toast.error("Product with SKU number already exists", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("There was an error adding the item:", error);
      }
    }
  };

  return (
    <section className="addNewItem common-section">
      <div className="container-fluid">
        <div className="container">
          <p className="addNewItem-title">Add a new item to your inventory</p>
          <p className="addNewItem-subtitle">
            You can add items one at a time or in bulk
          </p>
          <div>
            <form onSubmit={handleSubmit}>
              <p className="addNewItem-text">Sku No.</p>
              <input
                type="text"
                className="form-control addNewItem-input"
                placeholder="Enter SKU number"
                name="prod_sku_no"
                value={formData.prod_sku_no}
                onChange={handleInputChange}
              />

              <p className="addNewItem-text">Product Name</p>
              <input
                type="text"
                className="form-control addNewItem-input"
                placeholder="Enter product name"
                name="prod_name"
                value={formData.prod_name}
                onChange={handleInputChange}
              />

              <div className="addNewItem-btn-wrapper">
                <button
                  type="submit"
                  className="btn btn-primary addNewItem-btn"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default AddNewItem;
