export const API_START_POINT = "https://backend.phninventory.com/api";

export const LOGIN_API = `${API_START_POINT}/login`;
export const DASHBOARD_COUNT = `${API_START_POINT}/dashboardCounts`;
export const CREATE_INSTALLER_POST_API = `${API_START_POINT}/create-installer`;
export const ALL_ORDER_LIST_API = `${API_START_POINT}/ordersList`;
export const CREATE_ORDER_API = `${API_START_POINT}/create-order`;
export const CREATE_ORDER_GET_INSTALLER_LIST_API = `${API_START_POINT}/installersList`;
export const CREATE_PRODUCT = `${API_START_POINT}/create-product`;
export const PRODUCT_LIST = `${API_START_POINT}/productsList`;
export const DASHBOARD_SCHOOL_LIST = `${API_START_POINT}/ordersListDashboard`;
export const UPDATE_INSTALLER_DATA = (inst_id) =>
    `${API_START_POINT}/update-installer/${inst_id}`;
export const DELETE_INSTALLER =(inst_id)=>  `${API_START_POINT}/delete-installer/${inst_id}`;
export const MANAGER_PROFILE = `${API_START_POINT}/manager-profile`;
export const UPDATE_MANAGER = `${API_START_POINT}/update-manager`;
export const GET_ACTIVITIES = `${API_START_POINT}/get-activities`;
export const GET_ISSUES_LIST = `${API_START_POINT}/listIssueReports`;


