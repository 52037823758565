import React, { useEffect, useState } from "react";
import "../Assets/Css/AddSchool.css";
import useFetchAPI from "../Custom Hooks/useFetchAPI";
import {
  CREATE_ORDER_GET_INSTALLER_LIST_API,
  API_START_POINT,
} from "../Components/AllApis";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import stateanddistricts from "../Assets/Json/StateAndDistricts.json";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../Components/Loader";

const EditSchool = () => {
  const location = useLocation();
  const order_id = location.state;
  const [preData, setPreData] = useState(null);
  const [dateInputType, setDateInputType] = useState("text");
  console.log(order_id);
  const handleDateFocus = () => {
    setDateInputType("date");
  };

  const handleDateBlur = (e) => {
    if (!e.target.value) {
      setDateInputType("text");
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);

    const token = localStorage.getItem("authToken");
    axios
      .get(`${API_START_POINT}/get-order-details/${order_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setLoading(false);

        console.log(response.data.order);
        setPreData(response.data.order);
        if (
          response.data.order &&
          (response.data.order.ord_surv_date === null ||
            response.data.order.ord_surv_date === "")
        ) {
          setDateInputType("date");
        } else {
          setDateInputType("text");
        }
      })
      .catch(function (error) {
        setLoading(false);

        console.error("Error fetching data:", error);
      });
  }, []);

  const [schoolData, setSchoolData] = useState({
    school_name: "",
    address: "",
    state: "",
    district: "",
    school_email_id: "",
    pincode: "",
    poc_name: "",
    poc_email_id: "",
    poc_mobile_no: "",
    principal_name: "",
    principal_email_id: "",
    principal_mobile_no: "",
    city: "",
    installer: "",
    labtype: "",
    board: "",
    date: "",
    time: "",
  });
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const [states] = useState(stateanddistricts.states);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [installers, setInstallers] = useState();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSchoolData({
      ...schoolData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  function getDistrictsByState(selectedState) {
    const stateObj = stateanddistricts.states.find(
      (state) => state.state === selectedState
    );

    return stateObj ? stateObj.districts : [];
  }

  const districts = getDistrictsByState(schoolData.state);
  console.log(preData);
  useEffect(() => {
    if (preData) {
      const isPOCSameAsPrincipal =
        preData.ord_poc_name === preData.ord_princi_name &&
        preData.ord_poc_phone === preData.ord_princi_phone &&
        preData.ord_poc_email === preData.ord_princi_email;

      setCheckboxChecked(isPOCSameAsPrincipal);

      setSchoolData({
        school_name: preData.ord_school_name,
        address: preData.ord_school_address,
        state: preData.ord_school_state,
        district: preData.ord_school_district,
        school_email_id: preData.ord_school_email,
        pincode: preData.ord_school_zipcode,
        poc_name: preData.ord_poc_name,
        poc_email_id: preData.ord_poc_email,
        poc_mobile_no: preData.ord_poc_phone,
        principal_name: preData.ord_princi_name,
        principal_email_id: preData.ord_princi_email,
        principal_mobile_no: preData.ord_princi_phone,
        city: preData.ord_school_city,
        installer: preData.ord_installer,
        labtype: preData.ord_school_lab_type,
        board: preData.ord_school_board,
        date: preData.ord_surv_date,
        time: preData.ord_surv_time,
      });
    }
  }, [preData]);
  const validateData = () => {
    let dataErrors = {};
    const nameRegx = /^[A-Z a-z . , & ']+$/;
    const mobileRegx = /^[6789][0123456789]{9}$/;
    const emailRegx = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9]+[.-][a-zA-Z][a-z.A-Z]+$/;

    if (!schoolData.school_name) {
      dataErrors.school_name = "Please enter school name.";
    }
    if (!schoolData.address) {
      dataErrors.address = "Please enter address.";
    }
    if (!schoolData.state) {
      dataErrors.state = "Please select state.";
    }
    if (!schoolData.district) {
      dataErrors.district = "Please select district.";
    }
    if (!schoolData.city) {
      dataErrors.city = "Please select city.";
    }
    if (!schoolData.board) {
      dataErrors.board = "Please select board.";
    }
    if (!schoolData.school_email_id) {
      dataErrors.school_email_id = "Please enter email id.";
    } else if (!emailRegx.test(schoolData.school_email_id)) {
      dataErrors.school_email_id = "Please enter a valid email id.";
    }
    if (!schoolData.principal_email_id) {
      dataErrors.principal_email_id = "Please enter email id.";
    } else if (!emailRegx.test(schoolData.principal_email_id)) {
      dataErrors.principal_email_id = "Please enter a valid email id.";
    }
    if (!schoolData.principal_mobile_no) {
      dataErrors.principal_mobile_no = "Please enter mobile no.";
    } else if (!mobileRegx.test(schoolData.principal_mobile_no)) {
      dataErrors.principal_mobile_no = "Please enter a valid mobile no.";
    }
    if (!schoolData.pincode) {
      dataErrors.pincode = "Please enter pincode.";
    }
    if (!schoolData.poc_email_id) {
      dataErrors.poc_email_id = "Please enter email id.";
    } else if (!emailRegx.test(schoolData.poc_email_id)) {
      dataErrors.poc_email_id = "Please enter a valid email id.";
    }
    if (!schoolData.poc_mobile_no) {
      dataErrors.poc_mobile_no = "Please enter mobile no.";
    } else if (!mobileRegx.test(schoolData.poc_mobile_no)) {
      dataErrors.poc_mobile_no = "Please enter a valid mobile no.";
    }
    if (!schoolData.poc_name) {
      dataErrors.poc_name = "Please enter POC name.";
    } else if (!nameRegx.test(schoolData.poc_name)) {
      dataErrors.poc_name = "Please enter a valid name.";
    }

    if (!schoolData.principal_name) {
      dataErrors.principal_name = "Please enter principal name.";
    } else if (!nameRegx.test(schoolData.principal_name)) {
      dataErrors.principal_name = "Please enter a valid name.";
    }

    if (!schoolData.labtype) {
      dataErrors.labtype = "Please select the type of lab.";
    }

    // if (!schoolData.date) {
    //   dataErrors.date = "Please select the date.";
    // }

    // if (!schoolData.time) {
    //   dataErrors.time = "Please select the time.";
    // }
    setErrors(dataErrors);
    return Object.keys(dataErrors).length === 0;
  };

  useEffect(() => {
    if (checkboxChecked) {
      setSchoolData((prevState) => ({
        ...prevState,
        poc_name: prevState.principal_name,
        poc_mobile_no: prevState.principal_mobile_no,
        poc_email_id: prevState.principal_email_id,
      }));
    } else if (preData) {
      setSchoolData((prevState) => ({
        ...prevState,
        poc_name: preData.ord_poc_name,
        poc_mobile_no: preData.ord_poc_phone,
        poc_email_id: preData.ord_poc_email,
      }));
    }
  }, [checkboxChecked, preData]);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    axios
      .get(CREATE_ORDER_GET_INSTALLER_LIST_API, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setInstallers(response.data.allInstallers);
      })
      .catch(function (error) {
        console.error("Error fetching data:", error);
      });
  }, []);
  const navigateToEditSchool = () => {
    navigate("/all-orders");
  };
  const token = localStorage.getItem("authToken");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateData()) {
      setErrors({});

      axios({
        method: "post",
        url: `${API_START_POINT}/update-order/${order_id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ord_school_name: schoolData.school_name,
          ord_school_email: schoolData.school_email_id,
          ord_poc_email: schoolData.poc_email_id,
          ord_poc_phone: schoolData.poc_mobile_no,
          ord_poc_name: schoolData.poc_name,
          ord_princi_email: schoolData.principal_email_id,
          ord_princi_phone: schoolData.principal_mobile_no,
          ord_princi_name: schoolData.principal_name,
          ord_school_address: schoolData.address,
          ord_school_city: schoolData.city,
          ord_school_state: schoolData.state,
          ord_district: schoolData.district,
          ord_school_zipcode: schoolData.pincode,
          ord_installer: schoolData.installer,
          ord_surv_date:
            dateInputType === "text"
              ? schoolData.date
              : changeDateFormat(schoolData.date),
          ord_surv_time: schoolData.time,
          ord_school_lab_type: schoolData.ord_school_lab_type,
          ord_school_board: schoolData.ord_school_board,
        },
      })
        .then(function (response) {
          console.log(response.data);

          if (response.data.message === "Order updated successfully.") {
            toast.success("Order updated successfully.", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              onClose: () => {
                navigateToEditSchool();
              },
            });
          }
        })
        .catch(function (error) {
          console.error("There was an error!", error);
          toast.error("Failed to create order and survey. Please try again.", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    }
  };

  function changeDateFormat(date) {
    const formatedDate = date?.split("-").reverse().join("-");
    return formatedDate;
  }

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setSchoolData((prevState) => ({
        ...prevState,
        poc_name: prevState.principal_name,
        poc_email_id: prevState.principal_email_id,
        poc_mobile_no: prevState.principal_mobile_no,
      }));
    } else {
      setSchoolData((prevState) => ({
        ...prevState,
        poc_name: "",
        poc_email_id: "",
        poc_mobile_no: "",
      }));
    }
  };

  return (
    <section className="common-section">
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid addSchool">
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <div className="container ps-5 pe-5 pb-5">
            <p className="addSchool-title">Edit Order</p>
            <div>
              <p className="common-sub-title mt-4">School Contact Details</p>

              <form action="" onSubmit={handleSubmit}>
                <p className="addSchool-text">School Name</p>
                <input
                  type="text"
                  className="form-control addSchool-input"
                  placeholder="Enter school name"
                  value={schoolData.school_name}
                  onChange={handleInputChange}
                  name="school_name"
                />
                {errors.school_name && (
                  <p className="error-text">{errors.school_name}</p>
                )}
                <p className="addSchool-text">Address</p>
                <input
                  type="text"
                  className="form-control addSchool-input"
                  placeholder="Enter the address"
                  value={schoolData.address}
                  onChange={handleInputChange}
                  name="address"
                />
                {errors.address && (
                  <p className="error-text">{errors.address}</p>
                )}
                <div className="row">
                  <div className="col-6">
                    <p className="addSchool-text">State</p>
                    <select
                      className="form-control form-select addSchool-input"
                      value={schoolData.state}
                      name="state"
                      onChange={handleInputChange}
                    >
                      <option value={schoolData.state}>
                        {schoolData.state}
                      </option>
                      {states.map((stateName, ind) => {
                        return (
                          <option value={stateName.state} key={ind}>
                            {stateName.state}
                          </option>
                        );
                      })}
                    </select>
                    {errors.state && (
                      <p className="error-text">{errors.state}</p>
                    )}
                  </div>
                  <div className="col-6">
                    <p className="addSchool-text">District</p>
                    <select
                      className="form-control form-select addSchool-input"
                      value={schoolData.district}
                      name="district"
                      onChange={handleInputChange}
                    >
                      <option value={schoolData.district}>
                        {schoolData.district}
                      </option>
                      {districts.map((district) => (
                        <option key={district} value={district}>
                          {district}
                        </option>
                      ))}
                    </select>

                    {errors.district && (
                      <p className="error-text">{errors.district}</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="addSchool-text">City</p>
                    <input
                      type="text"
                      className="form-control addSchool-input"
                      placeholder="Enter city"
                      value={schoolData.city}
                      onChange={handleInputChange}
                      name="city"
                    />
                    {errors.city && <p className="error-text">{errors.city}</p>}
                  </div>
                  <div className="col-6">
                    <p className="addSchool-text">Board</p>
                    <select
                      className="form-control form-select addSchool-input"
                      value={schoolData.board}
                      name="board"
                      onChange={handleInputChange}
                    >
                      <option value="">Select</option>
                      <option value="State Board">State Board</option>
                      <option value="CBSE">CBSE</option>
                      <option value="ICSE">ICSE</option>
                      <option value="NIOS">NIOS</option>
                      <option value="IB">IB</option>
                      <option value="CIE">CIE</option>
                    </select>
                    {errors.board && (
                      <p className="error-text">{errors.board}</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="addSchool-text">School Email ID</p>
                    <input
                      type="text"
                      className="form-control  addSchool-input"
                      placeholder="Enter school email id"
                      value={schoolData.school_email_id}
                      onChange={handleInputChange}
                      name="school_email_id"
                    />
                    {errors.school_email_id && (
                      <p className="error-text">{errors.school_email_id}</p>
                    )}
                  </div>
                  <div className="col-6">
                    <p className="addSchool-text">Pincode</p>
                    <input
                      type="text"
                      className="form-control addSchool-input"
                      placeholder="Enter pincode"
                      value={schoolData.pincode}
                      onChange={handleInputChange}
                      name="pincode"
                    />
                    {errors.pincode && (
                      <p className="error-text">{errors.pincode}</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <p className="addSchool-text">Type of Lab</p>
                    <select
                      className="form-control form-select addSchool-input"
                      value={schoolData.labtype}
                      onChange={handleInputChange}
                      name="labtype"
                    >
                      <option value="">Select</option>
                      <option value="CoeLab">CoE Lab</option>
                      <option value="Future Tech AI Lab -  TTP">
                        Future Tech AI Lab - TTP
                      </option>
                      <option value="Future Tech AI Lab - Dedicated Trainer">
                        Future Tech AI Lab - Dedicated Trainer
                      </option>
                      <option value="Centre of Excellence - CoE Lab">
                        Centre of Excellence - CoE Lab
                      </option>
                      <option value="Prototype and Innovation Lab">
                        Prototype and Innovation Lab
                      </option>
                      <option value="Entrepreneurship Lab">
                        Entrepreneurship Lab
                      </option>
                    </select>
                    {errors.labtype && (
                      <p className="error-text">{errors.labtype}</p>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <p className="addSchool-text">Installer</p>
                    <select
                      className="form-control form-select addSchool-input"
                      placeholder="Enter address"
                      value={schoolData.installer}
                      onChange={handleInputChange}
                      name="installer"
                    >
                      <option value="">Select</option>
                      {installers?.map((items, ind) => {
                        return (
                          <option value={items.inst_id} key={ind}>
                            {items.inst_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <p className="common-sub-title mt-4">
                  Principal Contact Details
                </p>
                <div className="row">
                  <div className="col-6">
                    <p className="addSchool-text">Principal Name</p>
                    <input
                      type="text"
                      className="form-control addSchool-input"
                      placeholder="Enter principal name"
                      value={schoolData.principal_name}
                      onChange={handleInputChange}
                      name="principal_name"
                    />
                    {errors.principal_name && (
                      <p className="error-text">{errors.principal_name}</p>
                    )}
                  </div>
                  <div className="col-6">
                    <p className="addSchool-text">Principal Mobile No</p>
                    <input
                      type="text"
                      className="form-control addSchool-input"
                      placeholder="Enter principal mobile no"
                      value={schoolData.principal_mobile_no}
                      onChange={handleInputChange}
                      name="principal_mobile_no"
                      maxLength={10}
                    />
                    {errors.principal_mobile_no && (
                      <p className="error-text">{errors.principal_mobile_no}</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="addSchool-text">Prinicipal Email ID</p>
                    <input
                      type="text"
                      className="form-control addSchool-input"
                      placeholder="Enter prinicipal email id"
                      value={schoolData.principal_email_id}
                      onChange={handleInputChange}
                      name="principal_email_id"
                    />
                    {errors.principal_email_id && (
                      <p className="error-text">{errors.principal_email_id}</p>
                    )}
                  </div>
                </div>

                <div className="checkbox d-flex gap-4 mt-4">
                  <input
                    type="checkbox"
                    checked={checkboxChecked}
                    onChange={handleCheckboxChange}
                    className="poc-checkbox"
                  />
                  <p className="common-sub-title">
                    POC same as Principal Details
                  </p>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="addSchool-text">POC Name</p>
                    <input
                      type="text"
                      className="form-control addSchool-input"
                      placeholder="Enter POC Name"
                      value={
                        checkboxChecked
                          ? schoolData.principal_name
                          : schoolData.poc_name
                      }
                      onChange={handleInputChange}
                      name="poc_name"
                      disabled={checkboxChecked}
                    />

                    {errors.poc_name && !checkboxChecked && (
                      <p className="error-text">{errors.poc_name}</p>
                    )}
                  </div>
                  <div className="col-6">
                    <p className="addSchool-text">POC Mobile No</p>
                    <input
                      type="text"
                      className="form-control addSchool-input"
                      placeholder="Enter POC Mobile No"
                      value={
                        checkboxChecked
                          ? schoolData.principal_mobile_no
                          : schoolData.poc_mobile_no
                      }
                      onChange={handleInputChange}
                      name="poc_mobile_no"
                      disabled={checkboxChecked}
                      maxLength={10}
                    />
                    {errors.poc_mobile_no && !checkboxChecked && (
                      <p className="error-text">{errors.poc_mobile_no}</p>
                    )}
                  </div>
                  <div className="col-6">
                    <p className="addSchool-text">POC Email ID</p>
                    <input
                      type="text"
                      className="form-control addSchool-input"
                      placeholder="Enter POC Email ID"
                      value={
                        checkboxChecked
                          ? schoolData.principal_email_id
                          : schoolData.poc_email_id
                      }
                      onChange={handleInputChange}
                      name="poc_email_id"
                      disabled={checkboxChecked}
                    />
                    {errors.poc_email_id && !checkboxChecked && (
                      <p className="error-text">{errors.poc_email_id}</p>
                    )}
                  </div>
                </div>
                <p className="common-sub-title mt-4">Survey Date & Time</p>

                <div className="row">
                  <div className="col-lg-6">
                    <p className="addSchool-text">Date</p>
                    <input
                      type={dateInputType}
                      className="form-control addSchool-date-time-input"
                      name="date"
                      onChange={handleInputChange}
                      value={schoolData.date}
                      onFocus={handleDateFocus}
                      onBlur={handleDateBlur}
                      min={new Date().toISOString().split("T")[0]}
                    />
                    {errors.date && <p className="error-text">{errors.date}</p>}
                  </div>
                  <div className="col-lg-6">
                    <p className="addSchool-text">Time</p>
                    <input
                      type="time"
                      className="form-control addSchool-date-time-input"
                      name="time"
                      onChange={handleInputChange}
                      value={schoolData.time}
                    />
                    {errors.time && <p className="error-text">{errors.time}</p>}
                  </div>
                </div>
                <div className="addSchool-btn-wrapper">
                  <button
                    type="submit"
                    className="btn btn-primary addSchool-btn"
                  >
                    Update Order
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default EditSchool;
