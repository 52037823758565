import React, { useState, useEffect } from "react";
import "../Assets/Css/Login.css";
import showIcon from "../Assets/Images/Login/show.png";
import hideIcon from "../Assets/Images/Login/hide.png";
import { Link, useNavigate } from "react-router-dom";
import useFetchAPI from "../Custom Hooks/useFetchAPI";
import { LOGIN_API } from "./AllApis";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [errorMsg, setErrorMsg] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const { data, error, loading, triggerFetch } = useFetchAPI(LOGIN_API, "POST");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formErrors = {};

    if (email === "") {
      formErrors.email = "Please enter an email address.";
    } else if (!validateEmail(email)) {
      formErrors.email = "Please enter a valid email address.";
    }
    if (!pass) {
      formErrors.pass = "Please enter a password.";
    }

    setErrorMsg(formErrors);

    if (Object.keys(formErrors).length === 0) {
      triggerFetch({
        email: email,
        password: pass,
      });
    }
  };


  useEffect(() => {
    if (data) {
      toast.success("Login Successful", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        onClose: () => {
          localStorage.setItem("authToken", data.token);
          navigate("/dashboard");
        },
      });
    }
    if (error) {
      toast.error("Please enter valid credentials", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [data, navigate, error]);
  useEffect(() => {
    localStorage.clear();
    // Replace current URL in history with the login page URL
    window.history.replaceState(null, "", "/");
  }, []);

  useEffect(() => {
    // Prevent going back to the previous page after logging out
    const clearHistory = () => {
      window.history.pushState(null, window.location.pathname);
      window.onpopstate = () => {
        navigate("/");
      };
    };
    clearHistory();
    return () => {
      window.onpopstate = null;
    };
  }, [navigate]);
  return (
    <section className="login-page">
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="container-fluid">
        <div
          className="container pt-5 ps-5 pe-5 pb-5"
          style={{
            boxShadow: "rgba(100, 100, 111, 0.2) 1px 1px 3px 3px",
            borderRadius: "20px",
          }}
        >
          <div>
            <p className="loginPage-title">Log In</p>
          </div>
          <form onSubmit={handleSubmit} className="login-form">
            <p className="labelText">Email ID</p>
            <input
              type="text"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
            />
            {errorMsg.email && (
              <p className="errorMsg-text">{errorMsg.email}</p>
            )}
            <p className="labelText">Password</p>
            <div className="password-input-wrapper">
              <input
                type={passwordVisible ? "text" : "password"}
                className="form-control mb-2"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                name="password"
                id="password"
              />
              <img
                src={passwordVisible ? showIcon : hideIcon}
                alt="eye-icon"
                className="login_show_pass_icon"
                onClick={togglePasswordVisibility}
              />
              {errorMsg.pass && (
                <p className="errorMsg-text">{errorMsg.pass}</p>
              )}
            </div>
            <input
              type="submit"
              value={loading ? "Loading..." : "Log In"}
              className="w-100 btn login-btn"
              disabled={loading}
            />
          </form>
          <div className="mt-3">
            <Link to="/reset-password" className="create-acc-text">
              {" "}
              Forgot Password?
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
