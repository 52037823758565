import React, { useEffect, useState } from "react";
import BlueButton from "../Components/BlueButton";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_START_POINT } from "../Components/AllApis";
import Toaster from "../Components/Toaster";
import useFetchAPI from "../Custom Hooks/useFetchAPI";
import Loader from "../Components/Loader";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

const VerifyLabSetup = () => {
  const [labDetails, setLabDetails] = useState({});
  const location = useLocation();
  const order_id = location?.state?.ord_id;
  const [completionSuccess, setCompletionSuccess] = useState(false);
  const [showToaster, setShowToaster] = useState({
    message: "",
    show: false,
  });

  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const { data, error, loading, triggerFetch } = useFetchAPI(
    `${API_START_POINT}/getLabSetupDetails/${order_id}`,
    "GET"
  );

  useEffect(() => {
    triggerFetch();
  }, []);

  useEffect(() => {
    if (data && data.labsetup) {
      setLabDetails(data.labsetup);
    }
  }, [data]);

  const completeHandler = () => {
    axios({
      method: "POST",
      url: `${API_START_POINT}/mgrApproveLabSetup/${order_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        setShowToaster({
          message: "Lab Setup approved and order status updated to Complete.",
          show: true,
        });
        setCompletionSuccess(true);
      })
      .catch(function (error) {
        setShowToaster({
          message: error.message,
          show: true,
        });
      });
  };

  const onclosefunction = () => {
    setShowToaster({ ...showToaster, show: false });
    if (completionSuccess) {
      navigate("/all-orders");
    }
  };
console.log(labDetails);
  return (
    <section className="common-section centerd-section">
      {loading ? (
        <Loader />
      ) : (
        <div className="container w-50" style={{ marginLeft: "30%" }}>
          {showToaster.show && (
            <Toaster showToaster={showToaster} onClose={onclosefunction} />
          )}
          <p className="common-title mt-5 mb-2">Verify Lab Setup</p>
          <p className="sub-title">Order#{order_id}</p>
          <p className="common-sub-title mt-5">Photos</p>
          <div className="lab-setup-images">
            {labDetails.ls_images ? (
              labDetails.ls_images.length === 0 ? (
                <p>No Photos Available</p>
              ) : (
                <PhotoProvider>
                  <div className="photo-view">
                    {labDetails.ls_images.map((image, index) => (
                      <PhotoView key={index} src={image}>
                        <img
                          src={image}
                          alt={`Lab Setup ${index + 1}`}
                          style={{
                            width: "200px",
                            height: "200px",
                            objectFit: "cover",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        />
                      </PhotoView>
                    ))}
                  </div>
                </PhotoProvider>
              )
            ) : (
              <p>No Photos Available</p>
            )}
          </div>

          <p className="common-sub-title mt-5">Signatures</p>
          <div>
            {labDetails.ls_client_app_sign ? (
              <img
                src={labDetails.ls_client_app_sign}
                alt="signature_img"
                width="200px"
              />
            ) : (
              <p>No sign available</p>
            )}
          </div>
          <div className="d-flex gap-2 mt-4">
            <img
              src={require("../Assets/Images/Common/Profile_pic.png")}
              alt="Profile pic"
              width="20px"
              height="20px"
            />
            <p className="title mb-2">
              {labDetails.ls_client_app_name || "-"}
            </p>
          </div>
          <div className="d-flex gap-2 ">
            <img
              src={require("../Assets/Images/Common/call.png")}
              alt="Phone pic"
              width="20px"
              height="20px"
            />
            <p className="sub-title">
              {labDetails.ls_client_app_phone || "-"}
            </p>
          </div>
          <div className="d-flex gap-2 ">
            <img
              src={require("../Assets/Images/Common/email.png")}
              alt="email pic"
              width="20px"
              height="20px"
            />
            <p className="sub-title">
              {labDetails.ls_client_app_email
                ? labDetails.ls_client_app_email
                : "-"}
            </p>
          </div>

          <div className="mt-5">
            {!(
              !labDetails || Object.values(labDetails).some((value) => !value)
            ) && (
              <BlueButton
                btnName="Mark as Complete"
                clickHandler={completeHandler}
              />
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default VerifyLabSetup;
